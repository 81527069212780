import { 
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  writeBatch
} from 'firebase/firestore';
import { db } from '../config';

export const updateCouponStatus = async (
  couponId: string,
  status: 'active' | 'completed' | 'cancelled',
  result?: 'won' | 'lost'
): Promise<void> => {
  try {
    const batch = writeBatch(db);

    const couponRef = doc(db, 'coupons', couponId);
    const couponSnap = await getDoc(couponRef);
    
    if (!couponSnap.exists()) {
      throw new Error('Coupon non trouvé');
    }

    const couponData = couponSnap.data();
    const pronosticRef = doc(db, 'pronostics', couponData.pronosticId);
    const pronosticSnap = await getDoc(pronosticRef);

    const updateData: any = {
      status,
      updatedAt: serverTimestamp()
    };

    if (result) {
      updateData.result = result;
    }

    batch.update(couponRef, updateData);

    // Update pronostic stats if coupon is completed
    if (status === 'completed' && result && pronosticSnap.exists()) {
      const pronosticData = pronosticSnap.data();
      const totalCoupons = pronosticData.totalCoupons || 0;
      const wonCoupons = (pronosticData.wonCoupons || 0) + (result === 'won' ? 1 : 0);
      const successRate = Math.round((wonCoupons / totalCoupons) * 100);

      batch.update(pronosticRef, {
        wonCoupons,
        successRate,
        updatedAt: serverTimestamp()
      });

      // Update all active coupons with new success rate
      batch.update(couponRef, {
        successRate,
        wonCoupons,
        totalCoupons
      });
    }

    await batch.commit();
  } catch (error: any) {
    console.error('Error updating coupon status:', error);
    throw error;
  }
};