import { useState, useEffect } from 'react';
import { doc, getDoc, collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase/config';
import toast from 'react-hot-toast';

export function useWallet(userId: string) {
  const [wallet, setWallet] = useState<any>(null);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (userId) {
      loadWalletData();
    }
  }, [userId]);

  const loadWalletData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Get wallet data
      const walletRef = doc(db, 'wallets', userId);
      const walletSnap = await getDoc(walletRef);
      
      if (walletSnap.exists()) {
        setWallet({
          id: walletSnap.id,
          ...walletSnap.data()
        });
      } else {
        // Create wallet if it doesn't exist
        const newWallet = {
          balance: 0,
          lockedAmount: 0,
          createdAt: new Date(),
          updatedAt: new Date()
        };
        await setDoc(walletRef, newWallet);
        setWallet({ id: userId, ...newWallet });
      }

      // Get transactions
      const transactionsRef = collection(db, 'transactions');
      const q = query(
        transactionsRef,
        where('userId', '==', userId),
        orderBy('createdAt', 'desc')
      );
      
      const transactionsSnap = await getDocs(q);
      setTransactions(
        transactionsSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate()
        }))
      );
    } catch (error: any) {
      console.error('Error loading wallet:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    wallet,
    transactions,
    loading,
    error,
    refresh: loadWalletData
  };
}