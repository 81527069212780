import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { useWallet } from '../hooks/useWallet';
import { getCoupons } from '../lib/firebase/coupons';
import DashboardHeader from '../components/dashboard/DashboardHeader';
import SearchControls from '../components/dashboard/SearchControls';
import CouponsList from '../components/dashboard/CouponsList';
import toast from 'react-hot-toast';

const UserDashboard = () => {
  const navigate = useNavigate();
  const { userData, logout } = useAuthStore();
  const { wallet } = useWallet(userData?.id || '');
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<'odds' | 'winrate' | 'popular'>('popular');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadCoupons();
  }, []);

  const loadCoupons = async () => {
    try {
      setLoading(true);
      setError(null);
      const { active } = await getCoupons();
      setCoupons(active);
    } catch (error: any) {
      console.error('Error loading coupons:', error);
      setError('Erreur lors du chargement des coupons');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      toast.success('Déconnexion réussie');
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Erreur lors de la déconnexion');
    }
  };

  const sortCoupons = (coupons: any[]) => {
    return [...coupons].sort((a, b) => {
      switch (sortBy) {
        case 'odds':
          return b.odds - a.odds;
        case 'winrate':
          return b.successRate - a.successRate;
        case 'popular':
          return (b.viewCount || 0) - (a.viewCount || 0);
        default:
          return 0;
      }
    });
  };

  const filteredCoupons = sortCoupons(coupons.filter(coupon => {
    const matchesSearch = searchTerm === '' || 
      coupon.description?.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesSport = !selectedSport || coupon.sport === selectedSport;
    
    return matchesSearch && matchesSport;
  }));

  return (
    <div className="max-w-6xl mx-auto px-4">
      <DashboardHeader
        userData={userData}
        walletBalance={wallet?.balance || 0}
        onLogout={handleLogout}
      />

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Coupons disponibles</h2>
        <Link 
          to="/subscriptions"
          className="text-blue-600 hover:text-blue-700 font-medium"
        >
        </Link>
      </div>

      <div className="mb-6">
        <SearchControls
          searchTerm={searchTerm}
          onSearch={setSearchTerm}
          placeholder="Rechercher un coupon..."
        />

        <CouponsList
          loading={loading}
          error={error}
          coupons={filteredCoupons}
          selectedSport={selectedSport}
          sortBy={sortBy}
          onSelectSport={setSelectedSport}
          onChangeSortBy={() => setSortBy(
            sortBy === 'odds' 
              ? 'winrate' 
              : sortBy === 'winrate' 
              ? 'popular' 
              : 'odds'
          )}
        />
      </div>
    </div>
  );
};

export default UserDashboard;