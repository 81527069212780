import React from 'react';
import { Wallet } from 'lucide-react';

interface WalletBalanceProps {
  balance: number;
  lastTransaction?: {
    type: 'deposit' | 'withdrawal';
    amount: number;
  };
}

const WalletBalance: React.FC<WalletBalanceProps> = ({ balance, lastTransaction }) => {
  return (
    <div className="bg-white rounded-xl shadow-md p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <div className="bg-blue-100 p-3 rounded-full">
            <Wallet className="h-6 w-6 text-blue-600" />
          </div>
          <div className="ml-4">
            <h2 className="text-lg font-semibold">Mon Portefeuille</h2>
            <p className="text-sm text-gray-500">Gérez vos fonds</p>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <p className="text-sm text-gray-600">Solde disponible</p>
          <p className="text-3xl font-bold text-blue-600">
            {balance.toLocaleString()} FCFA
          </p>
        </div>

        {lastTransaction && (
          <div className="text-sm text-gray-600">
            Dernière transaction: {lastTransaction.type === 'deposit' ? '+' : '-'}
            {lastTransaction.amount.toLocaleString()} FCFA
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletBalance;