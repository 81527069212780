import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserCheck, Lock, Mail } from 'lucide-react';
import { signIn } from '../lib/firebase/auth';
import { useAuthStore } from '../store/authStore';
import toast from 'react-hot-toast';

const PronosticLogin = () => {
  const navigate = useNavigate();
  const { login } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      const { type, userData } = await signIn(formData.email, formData.password, 'pronostic');
      
      if (userData) {
        login(type, userData);
        toast.success('Connexion réussie');
        navigate('/pronostic-dashboard');
      } else {
        throw new Error('Données de connexion invalides');
      }
    } catch (error: any) {
      console.error('Login error:', error);
      toast.error(error.message || 'Erreur de connexion');
      setFormData(prev => ({ ...prev, password: '' }));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="max-w-md mx-auto">
      <div className="text-center mb-8">
        <UserCheck size={48} className="mx-auto text-green-600 mb-4" />
        <h2 className="text-2xl font-bold">Connexion Pronostiqueur</h2>
        <p className="text-gray-600 mt-2">Accédez à votre espace pronostiqueur</p>
      </div>
      
      <div className="bg-white rounded-xl shadow-md p-8">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <div className="mt-1 relative">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="input-field pl-10"
                required
              />
              <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Mot de passe</label>
            <div className="mt-1 relative">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="input-field pl-10"
                required
              />
              <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>

          <button 
            type="submit" 
            className="w-full bg-green-600 text-white rounded-lg py-2 hover:bg-green-700 transition"
            disabled={loading}
          >
            {loading ? 'Connexion...' : 'Se connecter'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PronosticLogin;