import { 
  collection, 
  query, 
  where, 
  orderBy, 
  limit, 
  getDocs,
  doc,
  updateDoc,
  serverTimestamp 
} from 'firebase/firestore';
import { db } from './config';
import { normalizeSearchTerm } from '../utils/helpers';

export const getTopPronostics = async (limitCount = 10) => {
  try {
    const pronosticsRef = collection(db, 'pronostics');
    const q = query(
      pronosticsRef,
      where('status', '==', 'active'),
      orderBy('successRate', 'desc'),
      limit(limitCount)
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate(),
      updatedAt: doc.data().updatedAt?.toDate()
    }));
  } catch (error) {
    console.error('Error getting top pronostics:', error);
    return [];
  }
};

export const searchPronostics = async (searchTerm: string = '') => {
  try {
    const pronosticsRef = collection(db, 'pronostics');
    const constraints = [
      where('status', '==', 'active')
    ];

    if (searchTerm.trim()) {
      const term = normalizeSearchTerm(searchTerm.trim().toLowerCase());
      constraints.push(
        where('nameLower', '>=', term),
        where('nameLower', '<=', term + '\uf8ff')
      );
    }

    const q = query(
      pronosticsRef,
      ...constraints,
      orderBy('successRate', 'desc')
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate(),
      updatedAt: doc.data().updatedAt?.toDate()
    }));
  } catch (error) {
    console.error('Error searching pronostics:', error);
    return [];
  }
};

export const updatePronosticSettings = async (pronosticId: string, data: {
  communityLink?: string;
  specialSubscriptionPrice: number;
}) => {
  try {
    const pronosticRef = doc(db, 'pronostics', pronosticId);
    await updateDoc(pronosticRef, {
      ...data,
      updatedAt: serverTimestamp()
    });
    return true;
  } catch (error) {
    console.error('Error updating pronostic settings:', error);
    throw error;
  }
};