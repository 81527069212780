import React, { useState } from 'react';
import { CreditCard, Bitcoin, Smartphone } from 'lucide-react';
import { useAuthStore } from '../../store/authStore';
import { initStripePayment } from '../../lib/payment/stripe';
import { initCryptoPayment } from '../../lib/payment/crypto';
import { initOrangeMoneyPayment } from '../../lib/payment/orangeMoney';
import CryptoPaymentForm from '../payment/CryptoPaymentForm';
import toast from 'react-hot-toast';

interface DepositFormProps {
  onSuccess?: () => void;
}

const DepositForm: React.FC<DepositFormProps> = ({ onSuccess }) => {
  const { userData } = useAuthStore();
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [showCryptoForm, setShowCryptoForm] = useState(false);
  const [cryptoPaymentDetails, setCryptoPaymentDetails] = useState<any>(null);

  const handleDeposit = async (method: 'card' | 'crypto' | 'orange') => {
    if (!userData) {
      toast.error('Vous devez être connecté');
      return;
    }

    if (!amount || Number(amount) < 1000) {
      toast.error('Le montant minimum est de 1000 FCFA');
      return;
    }

    try {
      setLoading(true);

      const paymentData = {
        userId: userData.id,
        amount: Number(amount),
        type: 'deposit',
        description: 'Dépôt portefeuille',
        userDetails: {
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.name,
          phoneNumber: userData.phone
        }
      };

      switch (method) {
        case 'card':
          window.location.href = 'https://vente.paiementpro.net/depot-couponxbet/4992';
          break;

        case 'crypto':
          const cryptoResult = await initCryptoPayment(paymentData);
          if (cryptoResult?.success) {
            setCryptoPaymentDetails(cryptoResult);
            setShowCryptoForm(true);
          }
          break;

        case 'orange':
          window.location.href = 'https://vente.paiementpro.net/depot-couponxbet/4992';
          break;
      }

      setAmount('');
    } catch (error: any) {
      console.error(`${method} payment error:`, error);
      toast.error(error.message || `Erreur lors du paiement ${method}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCryptoConfirm = () => {
    setShowCryptoForm(false);
    setAmount('');
    onSuccess?.();
  };

  if (showCryptoForm && cryptoPaymentDetails) {
    return (
      <CryptoPaymentForm
        paymentDetails={cryptoPaymentDetails}
        onConfirm={handleCryptoConfirm}
        onBack={() => setShowCryptoForm(false)}
      />
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Montant à déposer
        </label>
        <div className="relative">
          <input
            type="number"
            min="1000"
            step="100"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Montant en FCFA"
          />
          <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">
            FCFA
          </span>
        </div>
      </div>

      <div className="space-y-3">
        <button
          onClick={() => handleDeposit('card')}
          disabled={loading}
          className="w-full flex items-center justify-center p-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition disabled:opacity-50"
        >
          <CreditCard className="w-5 h-5 mr-2" />
          {loading ? 'Traitement en cours...' : 'Carte bancaire'}
        </button>

        <button
          onClick={() => handleDeposit('orange')}
          disabled={loading}
          className="w-full flex items-center justify-center p-4 bg-orange-600 text-white rounded-lg hover:bg-orange-700 transition disabled:opacity-50"
        >
          <Smartphone className="w-5 h-5 mr-2" />
          {loading ? 'Traitement en cours...' : 'Orange Money'}
        </button>

        <button
          onClick={() => handleDeposit('crypto')}
          disabled={loading}
          className="w-full flex items-center justify-center p-4 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition disabled:opacity-50"
        >
          <Bitcoin className="w-5 h-5 mr-2" />
          {loading ? 'Traitement en cours...' : 'USDT (TRC20)'}
        </button>
      </div>
    </div>
  );
};

export default DepositForm;