import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  updateProfile,
  User as FirebaseUser
} from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from './config';
import { isAdmin } from './admin/auth';
import { User, Pronostic } from '../types';
import { normalizeSearchTerm } from '../utils/helpers';

// Register new user
export const registerUser = async (userData: Omit<User, 'id'>) => {
  try {
    const { email, password, ...otherData } = userData as any;
    const { user } = await createUserWithEmailAndPassword(auth, email, password);

    // Create user document
    await setDoc(doc(db, 'users', user.uid), {
      ...otherData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    // Initialize wallet with 0 balance
    await setDoc(doc(db, 'wallets', user.uid), {
      balance: 0,
      lockedAmount: 0,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    await updateProfile(user, {
      displayName: `${userData.firstName} ${userData.name}`
    });

    return {
      type: 'user' as const,
      userData: {
        id: user.uid,
        email: user.email,
        ...otherData
      }
    };
  } catch (error: any) {
    console.error('Registration error:', error);
    throw new Error(error.message || 'Error during registration');
  }
};

// Register new pronostic
export const registerPronostic = async (pronosticData: Omit<Pronostic, 'id'>) => {
  try {
    const { email, password, ...otherData } = pronosticData as any;
    const { user } = await createUserWithEmailAndPassword(auth, email, password);

    // Create pronostic document with normalized search fields
    await setDoc(doc(db, 'pronostics', user.uid), {
      ...otherData,
      nameLower: normalizeSearchTerm(otherData.name),
      firstNameLower: normalizeSearchTerm(otherData.firstName),
      subscribers: 0,
      successRate: 0,
      totalCoupons: 0,
      wonCoupons: 0,
      balance: 0,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    // Initialize wallet with 0 balance
    await setDoc(doc(db, 'wallets', user.uid), {
      balance: 0,
      lockedAmount: 0,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    await updateProfile(user, {
      displayName: `${pronosticData.firstName} ${pronosticData.name}`
    });

    return {
      type: 'pronostic' as const,
      userData: {
        id: user.uid,
        email: user.email,
        ...otherData
      }
    };
  } catch (error: any) {
    console.error('Pronostic registration error:', error);
    throw new Error(error.message || 'Error during registration');
  }
};

// Sign in user
export const signIn = async (email: string, password: string, type: 'user' | 'pronostic' | 'admin') => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    
    if (type === 'admin') {
      if (await isAdmin(user.uid)) {
        const adminDoc = await getDoc(doc(db, 'admins', user.uid));
        return {
          type: 'admin',
          userData: {
            id: user.uid,
            email: user.email,
            role: 'admin',
            ...adminDoc.data()
          }
        };
      }
      throw new Error('Not authorized as admin');
    }

    const collectionName = type === 'pronostic' ? 'pronostics' : 'users';
    const docRef = doc(db, collectionName, user.uid);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      await firebaseSignOut(auth);
      throw new Error('Invalid account type');
    }

    // Check if wallet exists, create if not
    const walletRef = doc(db, 'wallets', user.uid);
    const walletSnap = await getDoc(walletRef);
    
    if (!walletSnap.exists()) {
      await setDoc(walletRef, {
        balance: 0,
        lockedAmount: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
    }

    return {
      type,
      userData: {
        id: user.uid,
        email: user.email,
        ...docSnap.data()
      }
    };
  } catch (error: any) {
    console.error('Sign in error:', error);
    throw new Error('Invalid credentials');
  }
};

// Sign out user
export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
    return true;
  } catch (error) {
    console.error('Sign out error:', error);
    throw error;
  }
};

// Initialize auth state listener
export const initAuthStateListener = (callback: (userInfo: any) => void) => {
  return onAuthStateChanged(auth, async (user: FirebaseUser | null) => {
    if (user) {
      try {
        // Check admin first
        if (await isAdmin(user.uid)) {
          const adminDoc = await getDoc(doc(db, 'admins', user.uid));
          if (adminDoc.exists()) {
            callback({
              type: 'admin',
              userData: {
                id: user.uid,
                email: user.email,
                role: 'admin',
                ...adminDoc.data()
              }
            });
            return;
          }
        }

        // Then check pronostic
        const pronosticDoc = await getDoc(doc(db, 'pronostics', user.uid));
        if (pronosticDoc.exists()) {
          callback({
            type: 'pronostic',
            userData: {
              id: user.uid,
              email: user.email,
              ...pronosticDoc.data()
            }
          });
          return;
        }

        // Finally check user
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          callback({
            type: 'user',
            userData: {
              id: user.uid,
              email: user.email,
              ...userDoc.data()
            }
          });
          return;
        }

        // No valid user type found
        await firebaseSignOut(auth);
        callback(null);
      } catch (error) {
        console.error('Error in auth state listener:', error);
        await firebaseSignOut(auth);
        callback(null);
      }
    } else {
      callback(null);
    }
  });
};

// Get current user info
export const getCurrentUser = async () => {
  const user = auth.currentUser;
  if (!user) return null;

  try {
    // Check admin first
    if (await isAdmin(user.uid)) {
      const adminDoc = await getDoc(doc(db, 'admins', user.uid));
      if (adminDoc.exists()) {
        return {
          type: 'admin',
          userData: {
            id: user.uid,
            email: user.email,
            role: 'admin',
            ...adminDoc.data()
          }
        };
      }
    }

    // Then check pronostic
    const pronosticDoc = await getDoc(doc(db, 'pronostics', user.uid));
    if (pronosticDoc.exists()) {
      return {
        type: 'pronostic',
        userData: {
          id: user.uid,
          email: user.email,
          ...pronosticDoc.data()
        }
      };
    }

    // Finally check user
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (userDoc.exists()) {
      return {
        type: 'user',
        userData: {
          id: user.uid,
          email: user.email,
          ...userDoc.data()
        }
      };
    }

    return null;
  } catch (error) {
    console.error('Error getting current user:', error);
    return null;
  }
};