import { addDoc, collection, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase/config';
import { generatePaymentReference } from '../utils/helpers';

const USDT_TO_XOF_RATE = 600; // 1 USDT = 600 XOF
const USDT_WALLET = 'TVoaVcFehDrfYyMN9UHdM8GXWxzvicMcXe';

export const initCryptoPayment = async (paymentData: any) => {
  try {
    if (!paymentData.userId) {
      throw new Error('User ID is required');
    }

    // Calculate USDT amount
    const usdtAmount = Number((paymentData.amount / USDT_TO_XOF_RATE).toFixed(2));

    if (usdtAmount < 1) {
      throw new Error('Montant minimum: 1 USDT');
    }

    const reference = generatePaymentReference();

    // Create payment record
    const paymentRef = await addDoc(collection(db, 'payments'), {
      userId: paymentData.userId,
      type: paymentData.type,
      pronosticId: paymentData.pronosticId || null,
      amount: paymentData.amount,
      usdtAmount,
      method: 'crypto',
      status: 'pending',
      reference,
      customerEmail: paymentData.userDetails?.email,
      customerName: `${paymentData.userDetails?.firstName} ${paymentData.userDetails?.lastName}`,
      customerPhone: paymentData.userDetails?.phoneNumber,
      description: paymentData.description || 'Paiement USDT',
      walletAddress: USDT_WALLET,
      network: 'TRC20',
      currency: 'USDT',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    return {
      success: true,
      paymentId: paymentRef.id,
      reference,
      usdtAmount,
      walletAddress: USDT_WALLET
    };
  } catch (error: any) {
    console.error('Crypto payment error:', error);
    throw error;
  }
};

export const updateCryptoPayment = async (paymentId: string, senderAddress: string) => {
  try {
    if (!paymentId || !senderAddress) {
      throw new Error('Payment ID and sender address are required');
    }

    const paymentRef = doc(db, 'payments', paymentId);
    await updateDoc(paymentRef, {
      senderAddress,
      status: 'pending_confirmation',
      updatedAt: serverTimestamp()
    });

    return true;
  } catch (error: any) {
    console.error('Error updating crypto payment:', error);
    throw error;
  }
};