import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../config';

export const isAdmin = async (userId: string): Promise<boolean> => {
  if (!userId) return false;
  
  try {
    const adminRef = doc(db, 'admins', userId);
    const adminSnap = await getDoc(adminRef);
    return adminSnap.exists();
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

export const adminLogin = async (email: string, password: string) => {
  try {
    // First authenticate with Firebase Auth
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    
    // Then check if user is an admin
    const adminRef = doc(db, 'admins', user.uid);
    const adminSnap = await getDoc(adminRef);
    
    if (!adminSnap.exists()) {
      await auth.signOut();
      throw new Error('Not authorized as admin');
    }

    return {
      id: user.uid,
      email: user.email,
      role: 'admin',
      ...adminSnap.data()
    };
  } catch (error: any) {
    console.error('Admin login error:', error);
    throw new Error('Invalid admin credentials');
  }
};

export const createAdmin = async (userId: string, email: string) => {
  try {
    const adminRef = doc(db, 'admins', userId);
    await setDoc(adminRef, {
      email,
      role: 'admin',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });
    return true;
  } catch (error) {
    console.error('Error creating admin:', error);
    return false;
  }
};

export const verifyAdminAccess = async () => {
  const user = auth.currentUser;
  if (!user) return false;
  return isAdmin(user.uid);
};