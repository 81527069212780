import React from 'react';
import { Mail, User, Calendar, FileText } from 'lucide-react';
import { Link } from 'react-router-dom';

const PronosticApplication = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-12">
      <div className="bg-white rounded-xl shadow-md p-8">
        <h1 className="text-2xl font-bold mb-6">Devenir Pronostiqueur</h1>
        
        <div className="prose max-w-none">
          <p className="text-gray-600 mb-6">
            Pour devenir pronostiqueur sur CouponXBet, veuillez envoyer un email à{' '}
            <a 
              href="mailto:couponxbet@nabisstech.online" 
              className="text-blue-600 hover:text-blue-700"
            >
              couponxbet@nabisstech.online
            </a>
            {' '}avec les informations suivantes :
          </p>

          <div className="space-y-4 mb-8">
            <div className="flex items-start">
              <User className="w-5 h-5 text-blue-600 mt-1 mr-3" />
              <div>
                <h3 className="font-medium">Informations personnelles</h3>
                <p className="text-gray-600">Nom complet et prénom</p>
              </div>
            </div>

            <div className="flex items-start">
              <Calendar className="w-5 h-5 text-blue-600 mt-1 mr-3" />
              <div>
                <h3 className="font-medium">Date de naissance</h3>
                <p className="text-gray-600">Format JJ/MM/AAAA</p>
              </div>
            </div>

            <div className="flex items-start">
              <FileText className="w-5 h-5 text-blue-600 mt-1 mr-3" />
              <div>
                <h3 className="font-medium">Pièce d'identité</h3>
                <p className="text-gray-600">Photo ou scan d'une pièce d'identité valide</p>
              </div>
            </div>

            <div className="flex items-start">
              <Mail className="w-5 h-5 text-blue-600 mt-1 mr-3" />
              <div>
                <h3 className="font-medium">Contact</h3>
                <p className="text-gray-600">Email et numéro de téléphone</p>
              </div>
            </div>
          </div>

          <div className="bg-blue-50 p-6 rounded-lg">
            <h3 className="font-bold text-blue-900 mb-2">Note importante</h3>
            <p className="text-blue-800">
              Notre équipe examinera votre candidature et vous contactera dans les plus brefs délais. 
              Assurez-vous que toutes les informations fournies sont exactes et que la pièce d'identité 
              est clairement lisible.
            </p>
          </div>
        </div>

        <div className="mt-8 text-center">
          <Link 
            to="/"
            className="text-blue-600 hover:text-blue-700 font-medium"
          >
            Retour à l'accueil
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PronosticApplication;