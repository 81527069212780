import { 
  collection, 
  query, 
  where, 
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  onSnapshot,
  Timestamp,
  increment,
  serverTimestamp,
  writeBatch,
  and
} from 'firebase/firestore';
import { db } from './config';
import { processWalletPayment } from './wallet';
import toast from 'react-hot-toast';

export const getSubscriptionDetails = async (userId: string, pronosticId: string) => {
  try {
    const subscriptionsRef = collection(db, 'subscriptions');
    const q = query(
      subscriptionsRef,
      and(
        where('userId', '==', userId),
        where('pronosticId', '==', pronosticId),
        where('status', '==', 'active'),
        where('endDate', '>', Timestamp.now())
      )
    );
    
    const snapshot = await getDocs(q);
    if (snapshot.empty) return null;

    const doc = snapshot.docs[0];
    const data = doc.data();
    
    return {
      id: doc.id,
      ...data,
      startDate: data.startDate?.toDate(),
      endDate: data.endDate?.toDate(),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate()
    };
  } catch (error) {
    console.error('Error getting subscription details:', error);
    return null;
  }
};

export const getActiveSubscriptions = async (userId: string) => {
  if (!userId) return [];
  
  try {
    const subscriptionsRef = collection(db, 'subscriptions');
    const q = query(
      subscriptionsRef,
      and(
        where('userId', '==', userId),
        where('status', '==', 'active'),
        where('endDate', '>', Timestamp.now())
      )
    );

    const snapshot = await getDocs(q);
    const subscriptions = [];

    for (const docSnap of snapshot.docs) {
      const data = docSnap.data();
      if (data.pronosticId) {
        const pronosticRef = doc(db, 'pronostics', data.pronosticId);
        const pronosticSnap = await getDoc(pronosticRef);
        const pronosticData = pronosticSnap.data();

        if (pronosticData) {
          subscriptions.push({
            id: docSnap.id,
            ...data,
            pronosticName: `${pronosticData.firstName} ${pronosticData.name}`,
            successRate: pronosticData.successRate || 0,
            startDate: data.startDate?.toDate(),
            endDate: data.endDate?.toDate(),
            createdAt: data.createdAt?.toDate(),
            updatedAt: data.updatedAt?.toDate()
          });
        }
      }
    }

    return subscriptions;
  } catch (error) {
    console.error('Error getting active subscriptions:', error);
    throw error;
  }
};

export const checkSubscriptionStatus = async (userId: string, pronosticId?: string): Promise<boolean> => {
  try {
    const subscriptionsRef = collection(db, 'subscriptions');
    const constraints = [
      where('userId', '==', userId),
      where('status', '==', 'active'),
      where('endDate', '>', Timestamp.now())
    ];

    if (pronosticId) {
      constraints.push(where('pronosticId', '==', pronosticId));
    }

    const q = query(subscriptionsRef, and(...constraints));
    const snapshot = await getDocs(q);
    
    return !snapshot.empty;
  } catch (error) {
    console.error('Error checking subscription:', error);
    return false;
  }
};

export const subscribeToPronostic = async (
  userId: string,
  pronosticId: string,
  amount: number,
  method: string,
  userDetails: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  }
) => {
  try {
    const batch = writeBatch(db);
    
    // Create subscription document
    const subscriptionRef = collection(db, 'subscriptions');
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1);

    const subscriptionDoc = await addDoc(subscriptionRef, {
      userId,
      pronosticId,
      type: 'special',
      startDate: serverTimestamp(),
      endDate: Timestamp.fromDate(endDate),
      amount,
      status: 'active',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    // Update pronostic stats
    const pronosticRef = doc(db, 'pronostics', pronosticId);
    batch.update(pronosticRef, {
      subscribers: increment(1),
      balance: increment(amount),
      updatedAt: serverTimestamp()
    });

    // Process wallet payment
    await processWalletPayment(userId, amount, 'Abonnement spécial', async () => {
      // Create transaction record
      const transactionRef = collection(db, 'transactions');
      await addDoc(transactionRef, {
        userId,
        pronosticId,
        type: 'subscription',
        amount: -amount,
        method,
        status: 'completed',
        reference: subscriptionDoc.id,
        customerName: `${userDetails.firstName} ${userDetails.lastName}`,
        customerEmail: userDetails.email,
        phoneNumber: userDetails.phone,
        description: 'Abonnement spécial',
        createdAt: serverTimestamp()
      });

      // Commit all updates
      await batch.commit();
    });

    return true;
  } catch (error: any) {
    console.error('Subscription error:', error);
    throw error;
  }
};

export const listenToSubscriberCount = (pronosticId: string, callback: (count: number) => void) => {
  try {
    const pronosticRef = doc(db, 'pronostics', pronosticId);
    
    return onSnapshot(pronosticRef, (doc) => {
      if (doc.exists()) {
        callback(doc.data().subscribers || 0);
      } else {
        callback(0);
      }
    }, (error) => {
      console.error('Error listening to subscribers:', error);
      callback(0);
    });
  } catch (error) {
    console.error('Error setting up subscriber listener:', error);
    return () => {};
  }
};