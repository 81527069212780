import { 
  collection, 
  query, 
  where, 
  getDocs,
  orderBy
} from 'firebase/firestore';
import { db } from '../config';
import { Coupon } from '../../types';

export const getPronosticCoupons = async (pronosticId: string) => {
  try {
    if (!pronosticId) {
      throw new Error('ID du pronostiqueur requis');
    }

    const q = query(
      collection(db, 'coupons'),
      where('pronosticId', '==', pronosticId),
      orderBy('createdAt', 'desc')
    );

    const snapshot = await getDocs(q);
    const coupons = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      startDate: doc.data().startDate.toDate(),
      endDate: doc.data().endDate.toDate(),
      createdAt: doc.data().createdAt?.toDate(),
      updatedAt: doc.data().updatedAt?.toDate()
    }));

    return {
      active: coupons.filter(c => 
        c.status === 'active' && 
        new Date(c.endDate) >= new Date()
      ),
      history: coupons.filter(c => 
        c.status === 'completed' || 
        new Date(c.endDate) < new Date()
      )
    };
  } catch (error) {
    console.error('Error getting pronostic coupons:', error);
    throw error;
  }
};