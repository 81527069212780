import React from 'react';
import { Shield, Target, Users } from 'lucide-react';

const About = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <div className="bg-white rounded-xl shadow-md p-8">
        <h1 className="text-3xl font-bold mb-6">À propos de CouponXBet</h1>

        <div className="prose max-w-none">
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Notre Mission</h2>
            <p className="text-gray-600">
              CouponXBet est une plateforme de partage de pronostics sportifs qui met en relation les passionnés de sport avec des experts en analyse sportive. Notre mission est de créer un environnement transparent et sécurisé pour l'échange d'informations sportives.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-6 mb-8">
            <div className="bg-blue-50 p-6 rounded-lg">
              <Shield className="w-8 h-8 text-blue-600 mb-4" />
              <h3 className="font-bold mb-2">Transparence</h3>
              <p className="text-sm text-gray-600">
                Nous mettons l'accent sur la transparence totale des statistiques et des performances de nos analystes.
              </p>
            </div>

            <div className="bg-green-50 p-6 rounded-lg">
              <Target className="w-8 h-8 text-green-600 mb-4" />
              <h3 className="font-bold mb-2">Expertise</h3>
              <p className="text-sm text-gray-600">
                Nos analystes sont sélectionnés pour leur connaissance approfondie du sport et leur capacité d'analyse.
              </p>
            </div>

            <div className="bg-purple-50 p-6 rounded-lg">
              <Users className="w-8 h-8 text-purple-600 mb-4" />
              <h3 className="font-bold mb-2">Communauté</h3>
              <p className="text-sm text-gray-600">
                Une communauté active d'amateurs de sport partageant leur passion.
              </p>
            </div>
          </div>

          <div className="bg-yellow-50 p-6 rounded-lg mb-8">
            <h3 className="text-xl font-bold mb-4">Avis Important</h3>
            <p className="text-gray-800">
              CouponXBet n'est PAS une plateforme de paris sportifs. Nous ne prenons pas de paris et ne sommes pas associés à des bookmakers. Notre rôle se limite strictement au partage d'analyses sportives et de pronostics à titre informatif.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-bold mb-4">Contact</h2>
            <p className="text-gray-600">
              Pour toute question ou information, n'hésitez pas à nous contacter à :{' '}
              <a href="mailto:couponxbet@nabisstech.online" className="text-blue-600 hover:text-blue-700">
                couponxbet@nabisstech.online
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;