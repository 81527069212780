import React from 'react';
import { Link } from 'react-router-dom';
import { Trophy, Shield, Target } from 'lucide-react';

const Home = () => {
  return (
    <div className="max-w-6xl mx-auto">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-2xl p-8 md:p-12 mb-12 text-white">
        <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">
          La plateforme de référence pour des paris sportifs maîtrisés
        </h1>
        <p className="text-lg md:text-xl text-blue-100 mb-8">
          Accédez aux meilleurs pronostiqueurs et minimisez vos risques
        </p>
        <div className="flex flex-col sm:flex-row gap-4">
          <Link
            to="/inscription-utilisateur"
            className="px-6 py-3 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition text-center"
          >
            Commencer maintenant
          </Link>
          <Link
            to="/connexion-pronostiqueur"
            className="px-6 py-3 bg-blue-700 text-white rounded-lg hover:bg-blue-600 transition text-center"
          >
            Se connecter en tant que pronostiqueur
          </Link>
        </div>
      </div>

      {/* Features Section */}
      <div className="grid md:grid-cols-3 gap-8 mb-12">
        <div className="bg-white p-6 rounded-xl shadow-md">
          <div className="bg-blue-100 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
            <Trophy className="w-6 h-6 text-blue-600" />
          </div>
          <h3 className="text-xl font-bold mb-2">Pronostiqueurs experts</h3>
          <p className="text-gray-600">
            Des pronostiqueurs sélectionnés avec un historique de performance vérifié
          </p>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-md">
          <div className="bg-green-100 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
            <Shield className="w-6 h-6 text-green-600" />
          </div>
          <h3 className="text-xl font-bold mb-2">Risques maîtrisés</h3>
          <p className="text-gray-600">
            Des analyses détaillées pour des paris plus sûrs et réfléchis
          </p>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-md">
          <div className="bg-purple-100 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
            <Target className="w-6 h-6 text-purple-600" />
          </div>
          <h3 className="text-xl font-bold mb-2">Coupons ciblés</h3>
          <p className="text-gray-600">
            Des pronostics sélectionnés avec soin pour maximiser vos chances
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;