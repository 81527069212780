import React, { useState } from 'react';
import { Copy, Check, AlertTriangle } from 'lucide-react';
import { updateCryptoPayment } from '../../lib/payment/crypto';
import toast from 'react-hot-toast';

interface CryptoPaymentFormProps {
  paymentDetails: {
    paymentId: string;
    usdtAmount: number;
  };
  onConfirm: () => void;
  onBack: () => void;
}

const USDT_WALLET = 'TVoaVcFehDrfYyMN9UHdM8GXWxzvicMcXe';

const CryptoPaymentForm: React.FC<CryptoPaymentFormProps> = ({
  paymentDetails,
  onConfirm,
  onBack
}) => {
  const [senderAddress, setSenderAddress] = useState('');
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(USDT_WALLET);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      toast.success('Adresse copiée dans le presse-papier');
    } catch (error) {
      toast.error('Erreur lors de la copie de l\'adresse');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!senderAddress.trim()) {
      toast.error('Veuillez entrer votre adresse d\'envoi');
      return;
    }

    try {
      setLoading(true);
      await updateCryptoPayment(paymentDetails.paymentId, senderAddress);
      toast.success('Transaction soumise avec succès');
      onConfirm();
    } catch (error: any) {
      toast.error(error.message || 'Erreur lors de la confirmation');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg">
        <div className="flex items-start">
          <AlertTriangle className="h-6 w-6 text-yellow-400 mr-3 flex-shrink-0" />
          <div>
            <h3 className="text-lg font-bold text-yellow-800">
              Instructions de paiement USDT (TRC20)
            </h3>
            <div className="mt-2 text-yellow-700 space-y-2">
              <p>1. Copiez l'adresse USDT TRC20 ci-dessous</p>
              <p>2. Envoyez exactement {paymentDetails.usdtAmount} USDT</p>
              <p>3. Entrez l'adresse depuis laquelle vous avez envoyé les fonds</p>
              <p>4. Attendez la confirmation de l'administrateur</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 p-6 rounded-lg space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Montant à envoyer
          </label>
          <div className="text-3xl font-bold text-gray-900">
            {paymentDetails.usdtAmount} USDT
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Adresse USDT (TRC20)
          </label>
          <div className="flex items-center bg-white border rounded-lg p-3">
            <code className="flex-1 font-mono text-sm break-all">
              {USDT_WALLET}
            </code>
            <button
              onClick={handleCopy}
              className="ml-3 p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
              title="Copier l'adresse"
            >
              {copied ? <Check size={20} /> : <Copy size={20} />}
            </button>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Votre adresse d'envoi (TRC20)
          </label>
          <input
            type="text"
            className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            value={senderAddress}
            onChange={(e) => setSenderAddress(e.target.value)}
            placeholder="Entrez l'adresse depuis laquelle vous avez envoyé"
            required
          />
        </div>

        <div className="flex space-x-4">
          <button
            type="button"
            onClick={onBack}
            className="flex-1 px-6 py-3 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 font-medium"
          >
            Retour
          </button>
          <button
            type="submit"
            disabled={loading}
            className="flex-1 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium disabled:opacity-50"
          >
            {loading ? 'Traitement...' : 'Confirmer le paiement'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CryptoPaymentForm;