import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Trophy, Calendar } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useAuthStore } from '../store/authStore';
import { getActiveSubscriptions } from '../lib/firebase/subscriptions';
import BackButton from '../components/BackButton';
import toast from 'react-hot-toast';

const UserSubscriptions = () => {
  const { userData } = useAuthStore();
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (userData?.id) {
      loadSubscriptions();
    }
  }, [userData?.id]);

  const loadSubscriptions = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getActiveSubscriptions(userData?.id || '');
      setSubscriptions(data);
    } catch (error: any) {
      console.error('Error loading subscriptions:', error);
      setError('Erreur lors du chargement des abonnements');
      toast.error('Erreur lors du chargement des abonnements');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-6xl mx-auto px-4">
        <BackButton to="/dashboard" />
        <div className="text-center py-8">Chargement des abonnements...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-6xl mx-auto px-4">
        <BackButton to="/dashboard" />
        <div className="text-center py-8 text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4">
      <BackButton to="/dashboard" />
      
      <div className="mb-8">
        <h1 className="text-2xl font-bold mb-4">Mes Abonnements</h1>
        
        {subscriptions.length === 0 ? (
          <div className="bg-white rounded-lg shadow p-6 text-center">
            <p className="text-gray-500 mb-4">Vous n'avez aucun abonnement actif</p>
            <Link 
              to="/dashboard" 
              className="inline-block px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Découvrir les pronostiqueurs
            </Link>
          </div>
        ) : (
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {subscriptions.map((subscription) => (
              <div key={subscription.id} className="bg-white rounded-lg shadow p-6">
                <div className="flex items-start justify-between">
                  <div>
                    <Link 
                      to={`/pronostiqueur/${subscription.pronosticId}`}
                      className="text-lg font-semibold hover:text-blue-600"
                    >
                      {subscription.pronosticName}
                    </Link>
                    <div className="flex items-center text-sm text-gray-600 mt-2">
                      <Trophy className="w-4 h-4 text-yellow-500 mr-1" />
                      <span>{subscription.successRate}% réussite</span>
                    </div>
                    <div className="flex items-center text-sm text-gray-600 mt-2">
                      <Calendar className="w-4 h-4 mr-1" />
                      <span>
                        Expire le {format(new Date(subscription.endDate), 'dd MMMM yyyy', { locale: fr })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSubscriptions;