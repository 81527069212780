import { 
  collection, 
  doc,
  getDoc,
  addDoc,
  updateDoc,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore';
import { auth, db } from '../config';
import { Coupon } from '../../types';

export const createCoupon = async (pronosticId: string, couponData: any): Promise<Coupon> => {
  try {
    // Check if user is authenticated and is a pronostic
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('Vous devez être connecté');
    }

    // Verify user is a pronostic
    const pronosticRef = doc(db, 'pronostics', currentUser.uid);
    const pronosticSnap = await getDoc(pronosticRef);
    
    if (!pronosticSnap.exists()) {
      throw new Error('Vous devez être connecté en tant que pronostiqueur');
    }

    // Verify it's the same pronostic
    if (currentUser.uid !== pronosticId) {
      throw new Error('Vous ne pouvez pas créer des coupons pour d\'autres pronostiqueurs');
    }

    const pronosticData = pronosticSnap.data();

    // Calculate current stats
    const totalCoupons = pronosticData.totalCoupons || 0;
    const wonCoupons = pronosticData.wonCoupons || 0;
    const successRate = totalCoupons > 0 ? Math.round((wonCoupons / totalCoupons) * 100) : 0;

    // Clean and validate data
    const cleanedData = {
      pronosticId,
      pronosticName: `${pronosticData.firstName} ${pronosticData.name}`,
      successRate,
      wonCoupons,
      totalCoupons,
      sport: couponData.sport.trim(),
      startDate: Timestamp.fromDate(new Date(couponData.startDate)),
      endDate: Timestamp.fromDate(new Date(couponData.endDate)),
      odds: Number(couponData.odds),
      description: couponData.description?.trim() || '',
      code: couponData.code.trim(),
      status: 'active',
      viewCount: 0,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    };

    // Only add events if they exist and are valid
    if (Array.isArray(couponData.events) && couponData.events.length > 0) {
      const validEvents = couponData.events
        .filter((event: any) => 
          event && 
          typeof event.team1 === 'string' && 
          typeof event.team2 === 'string' && 
          typeof event.prediction === 'string' && 
          !isNaN(Number(event.odds))
        )
        .map((event: any) => ({
          team1: event.team1.trim(),
          team2: event.team2.trim(),
          prediction: event.prediction.trim(),
          odds: Number(event.odds)
        }));

      if (validEvents.length > 0) {
        cleanedData.events = validEvents;
      }
    }

    // Create coupon
    const couponRef = await addDoc(collection(db, 'coupons'), cleanedData);

    // Update pronostic's total coupons
    await updateDoc(pronosticRef, {
      totalCoupons: (pronosticData.totalCoupons || 0) + 1,
      updatedAt: serverTimestamp()
    });

    const newCouponSnap = await getDoc(couponRef);
    const newCouponData = newCouponSnap.data();
    
    return {
      id: couponRef.id,
      ...newCouponData,
      startDate: newCouponData?.startDate.toDate(),
      endDate: newCouponData?.endDate.toDate(),
      createdAt: newCouponData?.createdAt?.toDate(),
      updatedAt: newCouponData?.updatedAt?.toDate()
    } as Coupon;
  } catch (error: any) {
    console.error('Error creating coupon:', error);
    throw error;
  }
};