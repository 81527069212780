import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import UserLogin from './pages/UserLogin';
import UserRegister from './pages/UserRegister';
import PronosticLogin from './pages/PronosticLogin';
import PronosticRegister from './pages/PronosticRegister';
import UserDashboard from './pages/UserDashboard';
import UserSubscriptions from './pages/UserSubscriptions';
import PronosticDashboard from './pages/PronosticDashboard';
import PronosticWallet from './pages/PronosticWallet';
import PronosticSettings from './pages/PronosticSettings';
import UserProfile from './pages/UserProfile';
import PronosticProfile from './pages/PronosticProfile';
import PronosticApplication from './pages/PronosticApplication';
import Subscription from './pages/Subscription';
import Wallet from './pages/Wallet';
import About from './pages/About';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentCancel from './pages/PaymentCancel';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Navbar />
        <main className="flex-grow container mx-auto px-4 py-8">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/connexion-utilisateur" element={<UserLogin />} />
            <Route path="/inscription-utilisateur" element={<UserRegister />} />
            <Route path="/connexion-pronostiqueur" element={<PronosticLogin />} />
            <Route path="/inscription-pronostiqueur" element={<PronosticRegister />} />
            <Route path="/dashboard" element={<UserDashboard />} />
            <Route path="/subscriptions" element={<UserSubscriptions />} />
            <Route path="/pronostic-dashboard" element={<PronosticDashboard />} />
            <Route path="/pronostic-wallet" element={<PronosticWallet />} />
            <Route path="/pronostic-settings" element={<PronosticSettings />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/pronostiqueur/:id" element={<PronosticProfile />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/about" element={<About />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/payment/success" element={<PaymentSuccess />} />
            <Route path="/payment/cancel" element={<PaymentCancel />} />
            <Route path="/devenir-pronostiqueur" element={<PronosticApplication />} />
          </Routes>
        </main>
        <Footer />
        <Toaster position="top-right" />
      </div>
    </Router>
  );
}

export default App;