import React from 'react';
import { Link } from 'react-router-dom';
import { Trophy, User, LogOut } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { signOut } from '../lib/firebase/auth';
import toast from 'react-hot-toast';

const Navbar = () => {
  const { isLoggedIn, userType, logout } = useAuthStore();

  const handleLogout = async () => {
    try {
      await signOut();
      logout();
      toast.success('Déconnexion réussie');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Erreur lors de la déconnexion');
    }
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex items-center space-x-2 text-xl font-bold">
            <Trophy size={24} />
            <span>CouponXBet</span>
          </Link>

          <div className="flex items-center space-x-4">
            {isLoggedIn ? (
              <>
                <Link
                  to={userType === 'pronostic' ? '/pronostic-dashboard' : '/dashboard'}
                  className="text-gray-700 hover:text-blue-600"
                >
                  Tableau de bord
                </Link>
                <button
                  onClick={handleLogout}
                  className="flex items-center text-gray-700 hover:text-blue-600"
                >
                  <LogOut size={20} className="mr-1" />
                  <span>Déconnexion</span>
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/connexion-utilisateur"
                  className="flex items-center text-gray-700 hover:text-blue-600"
                >
                  <User size={20} className="mr-1" />
                  <span>Connexion</span>
                </Link>
                <Link
                  to="/connexion-pronostiqueur"
                  className="flex items-center text-gray-700 hover:text-blue-600"
                >
                  <Trophy size={20} className="mr-1" />
                  <span>Pronostiqueur</span>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;