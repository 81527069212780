import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

interface BackButtonProps {
  to: string;
  label?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ to, label = 'Retour' }) => {
  return (
    <Link 
      to={to}
      className="inline-flex items-center text-blue-600 hover:text-blue-700 mb-6"
    >
      <ArrowLeft className="mr-2" size={20} />
      {label}
    </Link>
  );
};

export default BackButton;