import { useState, useEffect } from 'react';
import { useAuthStore } from '../store/authStore';
import { 
  createCoupon,
  getPronosticCoupons,
  getTodayCoupons,
  updateCouponStatus,
  searchCoupons
} from '../lib/firebase/coupons';
import { Coupon } from '../types';
import toast from 'react-hot-toast';

export function useCoupons(pronosticId?: string) {
  const [coupons, setCoupons] = useState<{
    active: Coupon[];
    history: Coupon[];
  }>({ active: [], history: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { userData } = useAuthStore();

  useEffect(() => {
    if (pronosticId) {
      loadCoupons();
    }
  }, [pronosticId]);

  const loadCoupons = async () => {
    if (!pronosticId) return;

    try {
      setLoading(true);
      setError(null);
      const data = await getPronosticCoupons(pronosticId);
      setCoupons(data);
    } catch (err: any) {
      console.error('Error loading coupons:', err);
      setError(err.message || 'Erreur lors du chargement des coupons');
      toast.error(err.message || 'Erreur lors du chargement des coupons');
    } finally {
      setLoading(false);
    }
  };

  const submitCoupon = async (couponData: Omit<Coupon, 'id' | 'pronosticId'>) => {
    if (!userData?.id) {
      toast.error('Vous devez être connecté');
      return null;
    }

    try {
      setError(null);
      const newCoupon = await createCoupon(userData.id, couponData);
      await loadCoupons();
      return newCoupon;
    } catch (err: any) {
      console.error('Error submitting coupon:', err);
      setError(err.message || 'Erreur lors de la création du coupon');
      throw err;
    }
  };

  const updateStatus = async (couponId: string, status: 'active' | 'completed' | 'cancelled', result?: 'won' | 'lost') => {
    try {
      setError(null);
      await updateCouponStatus(couponId, status, result);
      await loadCoupons();
      return true;
    } catch (err: any) {
      console.error('Error updating coupon status:', err);
      setError(err.message || 'Erreur lors de la mise à jour du coupon');
      return false;
    }
  };

  const searchWithFilters = async (filters: Parameters<typeof searchCoupons>[0]) => {
    try {
      setLoading(true);
      setError(null);
      const results = await searchCoupons(filters);
      setCoupons({
        active: results.filter(c => c.status === 'active'),
        history: results.filter(c => c.status !== 'active')
      });
      return true;
    } catch (err: any) {
      console.error('Error searching coupons:', err);
      setError(err.message || 'Erreur lors de la recherche');
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    coupons,
    loading,
    error,
    submitCoupon,
    updateStatus,
    searchWithFilters,
    refresh: loadCoupons,
  };
}