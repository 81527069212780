import React, { useState } from 'react';
import { X } from 'lucide-react';
import { SPORTS_CATEGORIES } from '../lib/constants';
import { useAuthStore } from '../store/authStore';
import { createCoupon } from '../lib/firebase/coupons';
import toast from 'react-hot-toast';

interface CreateCouponModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const CreateCouponModal: React.FC<CreateCouponModalProps> = ({ isOpen, onClose, onSuccess }) => {
  const { userData } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    sport: '',
    startDate: '',
    endDate: '',
    odds: '',
    description: '',
    code: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!userData?.id) {
      toast.error('Vous devez être connecté');
      return;
    }

    try {
      setLoading(true);
      await createCoupon(userData.id, formData);
      toast.success('Coupon créé avec succès');
      
      setFormData({
        sport: '',
        startDate: '',
        endDate: '',
        odds: '',
        description: '',
        code: ''
      });

      onSuccess?.();
      onClose();
    } catch (error: any) {
      console.error('Error creating coupon:', error);
      toast.error(error.message || 'Erreur lors de la création du coupon');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">Créer un nouveau coupon</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Sport</label>
            <select
              className="input-field"
              value={formData.sport}
              onChange={(e) => setFormData({ ...formData, sport: e.target.value })}
              required
            >
              <option value="">Sélectionner un sport</option>
              {SPORTS_CATEGORIES.map((sport) => (
                <option key={sport.id} value={sport.id}>
                  {sport.icon} {sport.name}
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Date de début</label>
              <input
                type="datetime-local"
                className="input-field"
                value={formData.startDate}
                onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Date de fin</label>
              <input
                type="datetime-local"
                className="input-field"
                value={formData.endDate}
                onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Cote totale</label>
            <input
              type="number"
              step="0.01"
              min="1"
              className="input-field"
              value={formData.odds}
              onChange={(e) => setFormData({ ...formData, odds: e.target.value })}
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Code du coupon</label>
            <input
              type="text"
              className="input-field"
              value={formData.code}
              onChange={(e) => setFormData({ ...formData, code: e.target.value })}
              placeholder="Code du coupon 1xBet"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              className="input-field"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              rows={4}
              placeholder="Votre analyse détaillée..."
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="btn-secondary"
              disabled={loading}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn-primary"
              disabled={loading}
            >
              {loading ? 'Création en cours...' : 'Créer le coupon'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCouponModal;