import React, { useState } from 'react';
import { Building2, Phone, Coins } from 'lucide-react';

const TAX_RATE = 0.30; // 30% tax rate

interface WithdrawalFormProps {
  maxAmount: number;
  onSubmit: (data: {
    amount: number;
    method: 'bank' | 'orange' | 'usdt';
    details: {
      bankAccount?: string;
      bankRouting?: string;
      phoneNumber?: string;
      usdtAddress?: string;
    };
  }) => Promise<void>;
  isProcessing?: boolean;
}

const WithdrawalForm: React.FC<WithdrawalFormProps> = ({
  maxAmount,
  onSubmit,
  isProcessing = false
}) => {
  const [amount, setAmount] = useState('');
  const [method, setMethod] = useState<'bank' | 'orange' | 'usdt'>('bank');
  const [details, setDetails] = useState({
    bankAccount: '',
    bankRouting: '',
    phoneNumber: '',
    usdtAddress: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const withdrawalAmount = Number(amount);
    if (withdrawalAmount < 1000) {
      return;
    }

    if (withdrawalAmount > maxAmount) {
      return;
    }

    const withdrawalDetails = {
      amount: withdrawalAmount,
      method,
      details: {
        ...(method === 'bank' && {
          bankAccount: details.bankAccount,
          bankRouting: details.bankRouting
        }),
        ...(method === 'orange' && {
          phoneNumber: details.phoneNumber
        }),
        ...(method === 'usdt' && {
          usdtAddress: details.usdtAddress
        })
      }
    };

    await onSubmit(withdrawalDetails);
    setAmount('');
    setDetails({
      bankAccount: '',
      bankRouting: '',
      phoneNumber: '',
      usdtAddress: ''
    });
  };

  const calculateTax = (amount: string) => {
    const value = Number(amount) || 0;
    const tax = value * TAX_RATE;
    const net = value - tax;
    return { tax, net };
  };

  const { tax, net } = calculateTax(amount);

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Montant à retirer
        </label>
        <div className="mt-1 relative">
          <input
            type="number"
            min="1000"
            step="100"
            className="input-field pl-10"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
          <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
            FCFA
          </div>
        </div>
        <p className="mt-2 text-sm text-gray-500">Minimum: 1000 FCFA</p>
      </div>

      {amount && Number(amount) > 0 && (
        <div className="bg-gray-50 p-4 rounded-lg space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Taxe (30%):</span>
            <span className="font-medium text-red-600">-{tax.toLocaleString()} FCFA</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Montant net:</span>
            <span className="font-medium text-green-600">{net.toLocaleString()} FCFA</span>
          </div>
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Méthode de retrait
        </label>
        <div className="grid grid-cols-3 gap-3">
          <button
            type="button"
            onClick={() => setMethod('bank')}
            className={`flex items-center justify-center px-4 py-2 border rounded-md ${
              method === 'bank'
                ? 'bg-blue-50 border-blue-500 text-blue-700'
                : 'border-gray-300 text-gray-700 hover:bg-gray-50'
            }`}
          >
            <Building2 className="w-5 h-5 mr-2" />
            <span className="hidden md:inline">Banque</span>
          </button>
          <button
            type="button"
            onClick={() => setMethod('orange')}
            className={`flex items-center justify-center px-4 py-2 border rounded-md ${
              method === 'orange'
                ? 'bg-blue-50 border-blue-500 text-blue-700'
                : 'border-gray-300 text-gray-700 hover:bg-gray-50'
            }`}
          >
            <Phone className="w-5 h-5 mr-2" />
            <span className="hidden md:inline">Orange Money</span>
          </button>
          <button
            type="button"
            onClick={() => setMethod('usdt')}
            className={`flex items-center justify-center px-4 py-2 border rounded-md ${
              method === 'usdt'
                ? 'bg-blue-50 border-blue-500 text-blue-700'
                : 'border-gray-300 text-gray-700 hover:bg-gray-50'
            }`}
          >
            <Coins className="w-5 h-5 mr-2" />
            <span className="hidden md:inline">USDT</span>
          </button>
        </div>
      </div>

      {/* Method-specific fields */}
      {method === 'bank' && (
        <>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Numéro de compte
            </label>
            <input
              type="text"
              className="input-field"
              value={details.bankAccount}
              onChange={(e) => setDetails({ ...details, bankAccount: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Code banque
            </label>
            <input
              type="text"
              className="input-field"
              value={details.bankRouting}
              onChange={(e) => setDetails({ ...details, bankRouting: e.target.value })}
              required
            />
          </div>
        </>
      )}

      {method === 'orange' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Numéro Orange Money(avec l'indicatif du pays)
          </label>
          <input
            type="tel"
            className="input-field"
            value={details.phoneNumber}
            onChange={(e) => setDetails({ ...details, phoneNumber: e.target.value })}
            pattern="[0-9]{11,}"
            placeholder="EXEMPLE: 0022677123456 |sans espace|"
            required
          />
        </div>
      )}

      {method === 'usdt' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Adresse USDT (TRC20)
          </label>
          <input
            type="text"
            className="input-field"
            value={details.usdtAddress}
            onChange={(e) => setDetails({ ...details, usdtAddress: e.target.value })}
            placeholder="Adresse TRC20"
            required
          />
        </div>
      )}

      <button
        type="submit"
        disabled={isProcessing}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
      >
        {isProcessing ? 'Traitement en cours...' : 'Demander le retrait'}
      </button>
    </form>
  );
};

export default WithdrawalForm;
