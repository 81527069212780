export const SPORTS_CATEGORIES = [
  { id: 'melange', name: 'Mélange de sport', icon: '🎯' },
  { id: 'football', name: 'Football', icon: '⚽' },
  { id: 'basketball', name: 'Basketball', icon: '🏀' },
  { id: 'tennis', name: 'Tennis', icon: '🎾' },
  { id: 'volleyball', name: 'Volleyball', icon: '🏐' },
  { id: 'handball', name: 'Handball', icon: '🤾' },
  { id: 'rugby', name: 'Rugby', icon: '🏉' },
  { id: 'hockey', name: 'Hockey', icon: '🏑' },
  { id: 'baseball', name: 'Baseball', icon: '⚾' },
  { id: 'mma', name: 'MMA', icon: '🥊' },
  { id: 'boxing', name: 'Boxe', icon: '🥊' },
] as const;

export const MOBILE_MONEY_PROVIDERS = [
  { id: 'mtn', name: 'MTN Money', icon: '💰' },
  { id: 'orange', name: 'Orange Money', icon: '🔸' },
  { id: 'moov', name: 'Moov Money', icon: '💳' },
] as const;