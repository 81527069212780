import React from 'react';
import { Link } from 'react-router-dom';
import { Wallet, LogOut } from 'lucide-react';

interface DashboardHeaderProps {
  userData: any;
  walletBalance: number;
  onLogout: () => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  userData,
  walletBalance,
  onLogout
}) => {
  return (
    <div className="bg-white rounded-xl shadow-md p-4 md:p-6 mb-8">
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
        <div>
          <h1 className="text-xl md:text-2xl font-bold">Tableau de bord</h1>
        </div>
        <div className="flex flex-col md:flex-row items-stretch md:items-center gap-2 md:gap-4">
          <Link
            to="/wallet"
            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition flex items-center justify-center"
          >
            <Wallet className="w-5 h-5 mr-2" />
            <div>
              <span className="block">Mon Portefeuille</span>
              <span className="text-sm text-green-100">
                {walletBalance?.toLocaleString() || 0} FCFA
              </span>
            </div>
          </Link>
          <button
            onClick={onLogout}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 border rounded-lg hover:bg-gray-50 flex items-center justify-center"
          >
            <LogOut className="w-5 h-5 mr-2" />
            <span>Déconnexion</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;