import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../config';
import { Coupon } from '../../types';

export const getCoupons = async ({ pronosticId, limit }: { pronosticId?: string; limit?: number } = {}) => {
  try {
    const couponsRef = collection(db, 'coupons');
    let q = query(couponsRef, orderBy('createdAt', 'desc'));

    if (pronosticId) {
      q = query(q, where('pronosticId', '==', pronosticId));
    }

    const snapshot = await getDocs(q);
    const now = new Date();

    const coupons = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      startDate: doc.data().startDate?.toDate(),
      endDate: doc.data().endDate?.toDate(),
      createdAt: doc.data().createdAt?.toDate(),
      updatedAt: doc.data().updatedAt?.toDate()
    }));

    return {
      active: coupons.filter(c => new Date(c.endDate) > now),
      history: coupons.filter(c => new Date(c.endDate) <= now)
    };
  } catch (error) {
    console.error('Error fetching coupons:', error);
    throw error;
  }
};

// Re-export other coupon functions
export * from './createCoupon';
export * from './updateCouponStatus';
export * from './getTodayCoupons';
export * from './getPronosticCoupons';
export * from './searchCoupons';
export * from './getCouponById';
export * from './couponViews';
export * from './deleteCoupon';