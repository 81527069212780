import { doc, deleteDoc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../config';

export const deleteCoupon = async (couponId: string, pronosticId: string): Promise<void> => {
  try {
    // Get coupon to verify ownership
    const couponRef = doc(db, 'coupons', couponId);
    const couponSnap = await getDoc(couponRef);
    
    if (!couponSnap.exists()) {
      throw new Error('Coupon non trouvé');
    }

    const couponData = couponSnap.data();
    if (couponData.pronosticId !== pronosticId) {
      throw new Error('Vous n\'êtes pas autorisé à supprimer ce coupon');
    }

    // Check if coupon can be deleted (only pending or active)
    if (!['pending', 'active'].includes(couponData.status)) {
      throw new Error('Ce coupon ne peut plus être supprimé');
    }

    // Delete the coupon
    await deleteDoc(couponRef);

    // Update pronostic's total coupons count
    const pronosticRef = doc(db, 'pronostics', pronosticId);
    await updateDoc(pronosticRef, {
      totalCoupons: increment(-1)
    });
  } catch (error: any) {
    console.error('Error deleting coupon:', error);
    throw error;
  }
};