import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/Tabs';
import BackButton from '../components/BackButton';
import WalletBalance from '../components/wallet/WalletBalance';
import DepositForm from '../components/wallet/DepositForm';
import WithdrawalForm from '../components/wallet/WithdrawalForm';
import TransactionHistory from '../components/wallet/TransactionHistory';
import { useAuthStore } from '../store/authStore';
import { useWallet } from '../hooks/useWallet';
import { requestWithdrawal } from '../lib/firebase/withdrawals';

const Wallet = () => {
  const { userData } = useAuthStore();
  const { wallet, transactions, loading, error, refresh } = useWallet(userData?.id || '');
  const [activeTab, setActiveTab] = useState('deposit');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleWithdrawal = async (data: {
    amount: number;
    method: 'bank' | 'orange' | 'usdt';
    details: {
      bankAccount?: string;
      bankRouting?: string;
      phoneNumber?: string;
      usdtAddress?: string;
    };
  }) => {
    if (!userData?.id) return;
    
    try {
      setIsProcessing(true);
      await requestWithdrawal(userData.id, data);
      await refresh();
      setActiveTab('history');
    } catch (error) {
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center py-8">Chargement du portefeuille...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center py-8 text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4">
      <BackButton to="/dashboard" />
      
      {wallet && (
        <>
          <WalletBalance 
            balance={wallet.balance}
            lastTransaction={transactions[0]}
          />

          <div className="mt-8">
            <Tabs value={activeTab} onValueChange={setActiveTab}>
              <TabsList>
                <TabsTrigger value="deposit">Dépôt</TabsTrigger>
                <TabsTrigger value="withdraw">Retrait</TabsTrigger>
                <TabsTrigger value="history">Historique</TabsTrigger>
              </TabsList>

              <TabsContent value="deposit">
                <div className="bg-white rounded-xl shadow-md p-6">
                  <DepositForm onSuccess={() => setActiveTab('history')} />
                </div>
              </TabsContent>

              <TabsContent value="withdraw">
                <div className="bg-white rounded-xl shadow-md p-6">
                  <WithdrawalForm
                    maxAmount={wallet.balance}
                    onSubmit={handleWithdrawal}
                    isProcessing={isProcessing}
                  />
                </div>
              </TabsContent>

              <TabsContent value="history">
                <TransactionHistory 
                  transactions={transactions}
                />
              </TabsContent>
            </Tabs>
          </div>
        </>
      )}
    </div>
  );
};

export default Wallet;