import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase/config';
import toast from 'react-hot-toast';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const paymentId = searchParams.get('payment_id');
    if (!paymentId) {
      navigate('/');
      return;
    }

    // Listen to payment status changes
    const unsubscribe = onSnapshot(doc(db, 'payments', paymentId), (doc) => {
      if (doc.exists()) {
        const payment = doc.data();
        if (payment.status === 'completed') {
          setLoading(false);
          toast.success('Paiement réussi !');
        }
      }
    });

    return () => unsubscribe();
  }, [searchParams, navigate]);

  return (
    <div className="max-w-md mx-auto mt-12">
      <div className="bg-white rounded-xl shadow-md p-8 text-center">
        <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
        <h2 className="text-2xl font-bold mb-2">Paiement réussi!</h2>
        <p className="text-gray-600 mb-6">
          {loading 
            ? 'Confirmation du paiement en cours...'
            : 'Votre paiement a été confirmé et votre compte a été mis à jour.'}
        </p>
        <button
          onClick={() => navigate('/dashboard')}
          className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Retour au tableau de bord
        </button>
      </div>
    </div>
  );
}

export default PaymentSuccess;