import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import './i18n';
import { initAuthStateListener } from './lib/firebase/auth';
import { useAuthStore } from './store/authStore';

// Initialize auth state listener
initAuthStateListener((userInfo) => {
  if (userInfo) {
    useAuthStore.getState().login(userInfo.type, userInfo.userData);
  } else {
    useAuthStore.getState().logout();
  }
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);