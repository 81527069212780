import React from 'react';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';
import { formatAmount, formatDateTime } from '../../lib/utils/helpers';

interface Transaction {
  id: string;
  type: 'deposit' | 'withdrawal';
  amount: number;
  status: 'pending' | 'completed' | 'failed';
  createdAt: Date;
  description?: string;
  method?: string;
  reference?: string;
  customerName?: string;
  customerEmail?: string;
}

interface TransactionHistoryProps {
  transactions: Transaction[];
  onLoadMore?: () => void;
  hasMore?: boolean;
}

const TransactionHistory: React.FC<TransactionHistoryProps> = ({ 
  transactions, 
  onLoadMore,
  hasMore = false
}) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'text-green-600';
      case 'pending':
        return 'text-yellow-600';
      case 'failed':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'completed':
        return 'Complété';
      case 'pending':
        return 'En attente';
      case 'failed':
        return 'Échoué';
      default:
        return status;
    }
  };

  const getTransactionType = (transaction: Transaction) => {
    if (transaction.type === 'withdrawal') return 'Retrait';
    if (transaction.customerName) return `Abonnement de ${transaction.customerName}`;
    return 'Dépôt';
  };

  return (
    <div className="space-y-4">
      {transactions.map((transaction) => (
        <div 
          key={transaction.id} 
          className="bg-white rounded-lg p-4 shadow-sm flex items-center justify-between"
        >
          <div className="flex items-center">
            {transaction.type === 'deposit' ? (
              <ArrowDownRight className="h-5 w-5 text-green-500 mr-3" />
            ) : (
              <ArrowUpRight className="h-5 w-5 text-red-500 mr-3" />
            )}
            <div>
              <p className="font-medium">
                {getTransactionType(transaction)}
              </p>
              <p className="text-sm text-gray-500">
                {formatDateTime(transaction.createdAt)}
              </p>
              {transaction.reference && (
                <p className="text-xs text-gray-400">
                  Ref: {transaction.reference}
                </p>
              )}
            </div>
          </div>
          
          <div className="text-right">
            <p className={`font-medium ${
              transaction.type === 'deposit' ? 'text-green-600' : 'text-red-600'
            }`}>
              {transaction.type === 'deposit' ? '+' : '-'}
              {formatAmount(Math.abs(transaction.amount))}
            </p>
            <p className={`text-sm ${getStatusColor(transaction.status)}`}>
              {getStatusText(transaction.status)}
            </p>
          </div>
        </div>
      ))}

      {transactions.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          Aucune transaction pour le moment
        </div>
      )}

      {hasMore && (
        <button
          onClick={onLoadMore}
          className="w-full py-2 text-blue-600 hover:text-blue-700 text-sm font-medium"
        >
          Charger plus de transactions
        </button>
      )}
    </div>
  );
};

export default TransactionHistory;