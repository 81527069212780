import React from 'react';
import { Search } from 'lucide-react';

interface SearchControlsProps {
  searchTerm: string;
  onSearch: (term: string) => void;
  placeholder: string;
}

const SearchControls: React.FC<SearchControlsProps> = ({
  searchTerm,
  onSearch,
  placeholder
}) => {
  return (
    <div className="flex flex-col md:flex-row gap-4 mb-4">
      <div className="flex-1 relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => onSearch(e.target.value)}
          placeholder={placeholder}
          className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
      </div>
    </div>
  );
};

export default SearchControls;