import React from 'react';
import { Calendar, Trophy, User } from 'lucide-react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import DeleteCouponButton from './DeleteCouponButton';
import { useAuthStore } from '../store/authStore';

interface CouponCardProps {
  coupon: any;
  showPronosticInfo?: boolean;
  onDelete?: () => void;
}

const CouponCard: React.FC<CouponCardProps> = ({ 
  coupon, 
  showPronosticInfo = true,
  onDelete 
}) => {
  const { userType, userData } = useAuthStore();

  const formatDate = (date: any) => {
    try {
      if (date?.toDate) {
        return format(date.toDate(), 'dd MMM yyyy HH:mm', { locale: fr });
      }
      if (date) {
        return format(new Date(date), 'dd MMM yyyy HH:mm', { locale: fr });
      }
      return 'Date non disponible';
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date non disponible';
    }
  };

  const isExpired = () => {
    try {
      const endDate = typeof coupon.endDate === 'object' && 'toDate' in coupon.endDate 
        ? coupon.endDate.toDate() 
        : new Date(coupon.endDate);
      return endDate <= new Date();
    } catch (error) {
      console.error('Error checking expiration:', error);
      return false;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-semibold">{coupon.sport}</h3>
          <div className="flex items-center text-gray-600 text-sm mt-1">
            <Calendar size={16} className="mr-1" />
            <span>{formatDate(coupon.startDate)}</span>
          </div>
        </div>
        <div className="text-right">
          <div className="text-lg font-bold text-blue-600">Cote {coupon.odds}</div>
          <div className={`text-sm ${isExpired() ? 'text-red-500' : 'text-green-500'}`}>
            {isExpired() ? 'Expiré' : 'En cours'}
          </div>
        </div>
      </div>

      {showPronosticInfo && (
        <Link 
          to={`/pronostiqueur/${coupon.pronosticId}`} 
          className="flex items-center text-gray-700 hover:text-blue-600 mb-4"
        >
          <User size={16} className="mr-2" />
          <span className="font-medium">{coupon.pronosticName}</span>
          <div className="flex items-center ml-2">
            <Trophy size={16} className="text-yellow-500 mr-1" />
            <span className="text-sm">{coupon.successRate}%</span>
          </div>
        </Link>
      )}

      <p className="text-gray-700 whitespace-pre-line mb-4">{coupon.description}</p>

      {userType === 'pronostic' && userData?.id === coupon.pronosticId && (
        <div className="flex justify-end">
          <DeleteCouponButton
            couponId={coupon.id}
            pronosticId={coupon.pronosticId}
            onDelete={onDelete}
          />
        </div>
      )}
    </div>
  );
};

export default CouponCard;