import React from 'react';
import { useSubscription } from '../hooks/useSubscription';
import { CreditCard, AlertTriangle } from 'lucide-react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const RenewalBanner = () => {
  const { daysLeft, shouldRenew, isNewUser } = useSubscription();

  if (!shouldRenew) return null;

  return (
    <div className={clsx(
      'p-4 rounded-lg mb-6',
      isNewUser ? 'bg-blue-50' : daysLeft <= 0 ? 'bg-red-50' : 'bg-yellow-50'
    )}>
      <div className="flex items-start">
        <AlertTriangle className={clsx(
          'flex-shrink-0 h-5 w-5 mt-1',
          isNewUser ? 'text-blue-400' : daysLeft <= 0 ? 'text-red-400' : 'text-yellow-400'
        )} />
        <div className="ml-3 flex-1 flex items-center justify-between">
          <div>
            <h3 className={clsx(
              'text-sm font-medium',
              isNewUser ? 'text-blue-800' : daysLeft <= 0 ? 'text-red-800' : 'text-yellow-800'
            )}>
              {isNewUser 
                ? 'Bienvenue sur CouponXbet !'
                : daysLeft <= 0 
                ? 'Votre abonnement a expiré'
                : `Votre abonnement expire dans ${daysLeft} jours`
              }
            </h3>
            <p className="text-sm text-gray-600 mt-1">
              {isNewUser
                ? 'Veuillez payer l\'abonnement VIP pour voir les coupons'
                : 'Renouvelez maintenant pour continuer à accéder aux pronostics'
              }
            </p>
          </div>
          <Link
            to="/subscription"
            className={clsx(
              'inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white',
              'bg-blue-600 hover:bg-blue-700 transition'
            )}
          >
            <CreditCard className="mr-2 h-4 w-4" />
            {isNewUser ? 'S\'abonner' : 'Renouveler l\'abonnement'}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RenewalBanner;