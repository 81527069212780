import React, { useState, useEffect } from 'react';
import { DollarSign, ArrowUpRight, ArrowDownRight, Check, X, Search } from 'lucide-react';
import { format } from 'date-fns';
import { useAuthStore } from '../../store/authStore';
import { listenToAllTransactions, processTransaction } from '../../lib/firebase/admin/transactions';
import toast from 'react-hot-toast';

const AdminTransactions = () => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [processingId, setProcessingId] = useState<string | null>(null);
  const { userData } = useAuthStore();

  useEffect(() => {
    const unsubscribe = listenToAllTransactions((transactions) => {
      setTransactions(transactions);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleStatusUpdate = async (
    transactionId: string, 
    type: 'deposit' | 'withdrawal',
    status: 'completed' | 'rejected'
  ) => {
    if (!userData?.id) {
      toast.error('Admin authentication required');
      return;
    }

    try {
      setProcessingId(transactionId);
      await processTransaction(transactionId, type, status, userData.id);
      toast.success(`Transaction ${status} successfully`);
    } catch (error: any) {
      console.error('Error updating transaction:', error);
      toast.error(error.message || 'Error updating transaction');
    } finally {
      setProcessingId(null);
    }
  };

  const canProcessTransaction = (transaction: any) => {
    // Allow processing for:
    // 1. Pending transactions
    // 2. Crypto transactions in pending_confirmation status
    return transaction.status === 'pending' || 
           (transaction.method === 'crypto' && transaction.status === 'pending_confirmation');
  };

  const filteredTransactions = transactions.filter(transaction => 
    transaction.customerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    transaction.reference?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    transaction.customerEmail?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div className="text-center py-8">Loading transactions...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Transaction Management</h2>
        <div className="relative w-64">
          <input
            type="text"
            placeholder="Search transactions..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">User</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Method</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredTransactions.map((transaction) => (
              <tr key={transaction.id}>
                <td className="px-6 py-4">
                  <div>
                    <div className="font-medium">{transaction.customerName}</div>
                    <div className="text-sm text-gray-500">{transaction.customerEmail}</div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    {transaction.type === 'deposit' ? (
                      <ArrowDownRight className="h-5 w-5 text-green-500 mr-2" />
                    ) : (
                      <ArrowUpRight className="h-5 w-5 text-red-500 mr-2" />
                    )}
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                      transaction.type === 'deposit' 
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}>
                      {transaction.type === 'deposit' ? 'Deposit' : 'Withdrawal'}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="font-medium">{transaction.amount.toLocaleString()} FCFA</div>
                  {transaction.method === 'crypto' && transaction.usdtAmount && (
                    <div className="text-sm text-gray-500">{transaction.usdtAmount} USDT</div>
                  )}
                </td>
                <td className="px-6 py-4">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    transaction.method === 'card'
                      ? 'bg-blue-100 text-blue-800'
                      : transaction.method === 'crypto'
                      ? 'bg-yellow-100 text-yellow-800'
                      : transaction.method === 'orange'
                      ? 'bg-orange-100 text-orange-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {transaction.method}
                  </span>
                </td>
                <td className="px-6 py-4">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    transaction.status === 'completed'
                      ? 'bg-green-100 text-green-800'
                      : transaction.status === 'pending' || transaction.status === 'pending_confirmation'
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {transaction.status}
                  </span>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {format(transaction.createdAt, 'dd/MM/yyyy HH:mm')}
                </td>
                <td className="px-6 py-4">
                  {canProcessTransaction(transaction) && (
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleStatusUpdate(
                          transaction.id,
                          transaction.type,
                          'completed'
                        )}
                        disabled={processingId === transaction.id}
                        className="p-2 rounded-full bg-green-100 text-green-600 hover:bg-green-200 disabled:opacity-50"
                        title="Approve"
                      >
                        <Check className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleStatusUpdate(
                          transaction.id,
                          transaction.type,
                          'rejected'
                        )}
                        disabled={processingId === transaction.id}
                        className="p-2 rounded-full bg-red-100 text-red-600 hover:bg-red-200 disabled:opacity-50"
                        title="Reject"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {filteredTransactions.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No transactions found
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminTransactions;