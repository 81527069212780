import React from 'react';
import { X, Wallet, AlertTriangle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';
import { useWallet } from '../../hooks/useWallet';
import { formatAmount } from '../../lib/utils/helpers';
import toast from 'react-hot-toast';

interface WalletPaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  amount: number;
  description: string;
  onConfirm: () => Promise<void>;
}

const WalletPaymentModal: React.FC<WalletPaymentModalProps> = ({
  isOpen,
  onClose,
  amount,
  description,
  onConfirm
}) => {
  const navigate = useNavigate();
  const { userData } = useAuthStore();
  const { wallet, loading } = useWallet(userData?.id || '');

  if (!isOpen) return null;

  const handleConfirm = async () => {
    try {
      if (!wallet) {
        throw new Error('Wallet not found');
      }

      if (wallet.balance < amount) {
        toast.error('Solde insuffisant');
        navigate('/wallet');
        return;
      }

      await onConfirm();
      toast.success('Paiement effectué avec succès');
      onClose();
    } catch (error: any) {
      console.error('Payment error:', error);
      toast.error(error.message || 'Erreur lors du paiement');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold">Payer avec le portefeuille</h3>
          <button 
            onClick={onClose} 
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        </div>

        <div className="space-y-6">
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <span className="text-gray-600">Solde disponible:</span>
              <div className="flex items-center">
                <Wallet className="w-5 h-5 text-gray-500 mr-2" />
                <span className="font-medium">{formatAmount(wallet?.balance || 0)}</span>
              </div>
            </div>
            <div className="flex items-center justify-between text-lg">
              <span className="font-medium">Montant à payer:</span>
              <span className="font-bold text-blue-600">
                {formatAmount(amount)}
              </span>
            </div>
          </div>

          {(!wallet || wallet.balance < amount) && (
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg">
              <div className="flex items-start">
                <AlertTriangle className="h-5 w-5 text-yellow-400 mr-3" />
                <div>
                  <h3 className="text-sm font-medium text-yellow-800">
                    Solde insuffisant
                  </h3>
                  <p className="mt-1 text-sm text-yellow-700">
                    Veuillez recharger votre portefeuille avant de continuer.
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="flex space-x-3">
            <button
              onClick={onClose}
              className="flex-1 px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              onClick={handleConfirm}
              disabled={loading || !wallet || wallet.balance < amount}
              className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Traitement...' : 'Confirmer'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletPaymentModal;