import React, { useState, useEffect } from 'react';
import { FileText, Check, X, Search } from 'lucide-react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../lib/firebase/config';
import { updateCouponResult } from '../../lib/firebase/admin/coupons';
import { format } from 'date-fns';
import { useAuthStore } from '../../store/authStore';
import toast from 'react-hot-toast';

const AdminCoupons = () => {
  const [coupons, setCoupons] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [processingId, setProcessingId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { userData } = useAuthStore();

  useEffect(() => {
    // Set up real-time listener for coupons
    const couponsRef = collection(db, 'coupons');
    const q = query(couponsRef, orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const couponData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        startDate: doc.data().startDate?.toDate(),
        endDate: doc.data().endDate?.toDate()
      }));
      setCoupons(couponData);
      setLoading(false);
    }, (error) => {
      console.error('Error loading coupons:', error);
      toast.error('Error loading coupons');
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleResultUpdate = async (couponId: string, result: 'won' | 'lost') => {
    if (!userData?.id) {
      toast.error('Admin authentication required');
      return;
    }

    try {
      setProcessingId(couponId);
      await updateCouponResult(couponId, result, userData.id);
      toast.success(`Coupon marked as ${result}`);
    } catch (error: any) {
      console.error('Error updating coupon result:', error);
      toast.error(error.message || 'Error updating coupon result');
    } finally {
      setProcessingId(null);
    }
  };

  const filteredCoupons = coupons.filter(coupon => 
    coupon.pronosticName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    coupon.sport?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div className="text-center py-8">Loading coupons...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Manage Coupons</h2>
        <div className="relative w-64">
          <input
            type="text"
            placeholder="Search coupons..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Coupon Details
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Pronostic
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Sport
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                End Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredCoupons.map((coupon) => (
              <tr key={coupon.id}>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <FileText className="h-5 w-5 text-gray-500 mr-3" />
                    <div>
                      <div className="text-sm font-medium text-gray-900">
                        Code: {coupon.code}
                      </div>
                      <div className="text-sm text-gray-500">
                        {coupon.description?.substring(0, 50)}...
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-900">
                    {coupon.pronosticName}
                  </div>
                  <div className="text-sm text-gray-500">
                    Success rate: {coupon.successRate || 0}%
                  </div>
                </td>
                <td className="px-6 py-4 text-sm text-gray-900">
                  {coupon.sport}
                </td>
                <td className="px-6 py-4">
                  <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                    coupon.status === 'completed'
                      ? coupon.result === 'won'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {coupon.status === 'completed' 
                      ? coupon.result === 'won' ? 'Won' : 'Lost'
                      : 'Active'}
                  </span>
                </td>
                <td className="px-6 py-4 text-sm text-gray-900">
                  {format(coupon.endDate, 'dd/MM/yyyy HH:mm')}
                </td>
                <td className="px-6 py-4">
                  {coupon.status !== 'completed' && new Date() >= coupon.endDate && (
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleResultUpdate(coupon.id, 'won')}
                        disabled={processingId === coupon.id}
                        className="p-2 rounded-full bg-green-100 text-green-600 hover:bg-green-200 disabled:opacity-50"
                        title="Mark as won"
                      >
                        <Check className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleResultUpdate(coupon.id, 'lost')}
                        disabled={processingId === coupon.id}
                        className="p-2 rounded-full bg-red-100 text-red-600 hover:bg-red-200 disabled:opacity-50"
                        title="Mark as lost"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {filteredCoupons.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No coupons found
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminCoupons;