import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Users, TrendingUp, Plus, Trophy, Wallet, Settings, Share2 } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { useWallet } from '../hooks/useWallet';
import { getPronosticCoupons } from '../lib/firebase/coupons';
import { listenToSubscriberCount } from '../lib/firebase/subscriptions';
import CreateCouponModal from '../components/CreateCouponModal';
import CouponCard from '../components/CouponCard';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/Tabs';
import toast from 'react-hot-toast';

const PronosticDashboard = () => {
  const navigate = useNavigate();
  const { userData, userType, isLoggedIn } = useAuthStore();
  const { wallet } = useWallet(userData?.id || '');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState<any>({ active: [], history: [] });
  const [subscriberCount, setSubscriberCount] = useState(0);

  useEffect(() => {
    if (!isLoggedIn || userType !== 'pronostic') {
      navigate('/connexion-pronostiqueur');
      return;
    }

    if (userData?.id) {
      loadCoupons();
      const unsubscribe = listenToSubscriberCount(userData.id, (count) => {
        setSubscriberCount(count);
      });

      return () => unsubscribe();
    }
  }, [isLoggedIn, userType, userData?.id, navigate]);

  const loadCoupons = async () => {
    try {
      setLoading(true);
      const data = await getPronosticCoupons(userData?.id || '');
      setCoupons(data);
    } catch (error: any) {
      console.error('Error loading coupons:', error);
      toast.error(error.message || 'Erreur lors du chargement des coupons');
    } finally {
      setLoading(false);
    }
  };

  const handleShareProfile = () => {
    const url = `${window.location.origin}/pronostiqueur/${userData?.id}`;
    navigator.clipboard.writeText(url);
    toast.success('Lien du profil copié !');
  };

  if (!isLoggedIn || userType !== 'pronostic') {
    return null;
  }

  return (
    <div className="max-w-6xl mx-auto px-4">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 gap-4">
        <h1 className="text-2xl font-bold">Tableau de bord Pronostiqueur</h1>
        <div className="flex flex-wrap items-center gap-3">
          <Link
            to="/pronostic-settings"
            className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
          >
            <Settings size={20} className="mr-2" />
            <span className="hidden md:inline">Paramètres</span>
          </Link>
          <button
            onClick={handleShareProfile}
            className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
          >
            <Share2 size={20} className="mr-2" />
            <span className="hidden md:inline">Partager mon profil</span>
          </button>
          <button
            onClick={() => setIsCreateModalOpen(true)}
            className="btn-primary flex items-center"
          >
            <Plus size={20} className="mr-2" />
            <span>Nouveau Coupon</span>
          </button>
        </div>
      </div>

      <div className="grid gap-4 md:grid-cols-3 mb-8">
        <Link
          to="/pronostic-wallet"
          className="bg-white rounded-lg shadow p-6 hover:shadow-md transition"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-600">Solde disponible</p>
              <h3 className="text-2xl font-bold text-blue-600">
                {wallet?.balance?.toLocaleString() || 0} FCFA
              </h3>
            </div>
            <Wallet className="text-blue-600" size={32} />
          </div>
        </Link>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-600">Abonnés</p>
              <h3 className="text-2xl font-bold text-purple-600">{subscriberCount}</h3>
            </div>
            <Users className="text-purple-600" size={32} />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-600">Taux de réussite</p>
              <h3 className="text-2xl font-bold text-green-600">
                {userData?.successRate || 0}%
              </h3>
              <p className="text-sm text-gray-500">
                {userData?.wonCoupons || 0}/{userData?.totalCoupons || 0} coupons gagnés
              </p>
            </div>
            <TrendingUp className="text-green-600" size={32} />
          </div>
        </div>
      </div>

      <Tabs defaultValue="active" className="space-y-6">
        <TabsList className="w-full flex space-x-2 overflow-x-auto">
          <TabsTrigger value="active" className="flex-1">Coupons actifs</TabsTrigger>
          <TabsTrigger value="history" className="flex-1">Historique</TabsTrigger>
        </TabsList>

        <TabsContent value="active">
          {loading ? (
            <div className="text-center py-8">Chargement des coupons...</div>
          ) : (
            <div className="grid gap-6 md:grid-cols-2">
              {coupons.active.map((coupon: any) => (
                <CouponCard 
                  key={coupon.id} 
                  coupon={coupon} 
                  showPronosticInfo={false}
                  onDelete={loadCoupons}
                />
              ))}
              {coupons.active.length === 0 && (
                <div className="col-span-2 text-center py-8 text-gray-500">
                  Aucun coupon actif pour le moment
                </div>
              )}
            </div>
          )}
        </TabsContent>

        <TabsContent value="history">
          <div className="grid gap-6 md:grid-cols-2">
            {coupons.history.map((coupon: any) => (
              <CouponCard 
                key={coupon.id} 
                coupon={coupon} 
                showPronosticInfo={false}
                onDelete={loadCoupons}
              />
            ))}
            {coupons.history.length === 0 && (
              <div className="col-span-2 text-center py-8 text-gray-500">
                Aucun historique disponible
              </div>
            )}
          </div>
        </TabsContent>
      </Tabs>

      <CreateCouponModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSuccess={() => {
          setIsCreateModalOpen(false);
          loadCoupons();
        }}
      />
    </div>
  );
};

export default PronosticDashboard;