import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User, Pronostic } from '../types';
import { initAuthStateListener } from '../lib/firebase/auth';

interface AuthState {
  isLoggedIn: boolean;
  userType: 'user' | 'pronostic' | 'admin' | null;
  userData: (User | Pronostic | { id: string; email: string; role: string }) | null;
  login: (type: 'user' | 'pronostic' | 'admin', userData: any) => void;
  logout: () => void;
  updateUserData: (data: Partial<User | Pronostic>) => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      isLoggedIn: false,
      userType: null,
      userData: null,
      login: (type, userData) => {
        set({
          isLoggedIn: true,
          userType: type,
          userData: { ...userData }
        });
      },
      logout: () => {
        set({
          isLoggedIn: false,
          userType: null,
          userData: null
        });
      },
      updateUserData: (data) => set((state) => ({
        userData: state.userData ? { ...state.userData, ...data } : null
      })),
    }),
    {
      name: '1xcoupon-auth',
      partialize: (state) => ({
        isLoggedIn: state.isLoggedIn,
        userType: state.userType,
        userData: state.userData,
      }),
    }
  )
);

// Initialize auth state listener
initAuthStateListener((userInfo) => {
  if (userInfo) {
    useAuthStore.getState().login(userInfo.type, userInfo.userData);
  } else {
    useAuthStore.getState().logout();
  }
});