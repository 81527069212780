import React, { useState } from 'react';
import { X, Wallet } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';
import { useWallet } from '../../hooks/useWallet';
import { subscribeToPronostic } from '../../lib/firebase/subscriptions';
import WalletPaymentModal from './WalletPaymentModal';
import toast from 'react-hot-toast';

interface SubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  pronosticId: string;
  price: number;
  pronosticName: string;
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  isOpen,
  onClose,
  pronosticId,
  price,
  pronosticName
}) => {
  const navigate = useNavigate();
  const { userData } = useAuthStore();
  const { wallet } = useWallet(userData?.id || '');
  const [showWalletPayment, setShowWalletPayment] = useState(false);

  if (!isOpen) return null;

  const handleSubscribe = () => {
    if (!userData) {
      toast.error('Vous devez être connecté pour vous abonner');
      navigate('/connexion-utilisateur');
      return;
    }

    // Check if user has sufficient balance
    if (!wallet || wallet.balance < price) {
      toast.error('Solde insuffisant. Veuillez recharger votre portefeuille.');
      navigate('/wallet');
      return;
    }

    setShowWalletPayment(true);
  };

  const handleWalletPayment = async () => {
    try {
      await subscribeToPronostic(
        userData?.id || '',
        pronosticId,
        price,
        'wallet',
        {
          email: userData?.email || '',
          firstName: userData?.firstName || '',
          lastName: userData?.name || '',
          phone: userData?.phone || ''
        }
      );
      toast.success('Abonnement effectué avec succès');
      onClose();
    } catch (error: any) {
      throw error;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold">S'abonner à {pronosticName}</h3>
          <button 
            onClick={onClose} 
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        </div>

        <div className="mb-6">
          <div className="text-center mb-2">
            <span className="text-2xl font-bold text-blue-600">
              {price.toLocaleString()} FCFA
            </span>
            <span className="text-gray-600">/mois</span>
          </div>
          <p className="text-center text-gray-600">
            Accédez à tous les coupons de {pronosticName}
          </p>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg mb-6">
          <div className="flex items-center justify-between">
            <span className="text-gray-600">Solde disponible:</span>
            <span className="font-medium">{wallet?.balance?.toLocaleString() || 0} FCFA</span>
          </div>
        </div>

        <button
          onClick={handleSubscribe}
          className="w-full flex items-center justify-center p-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
        >
          <Wallet className="w-5 h-5 mr-2" />
          Payer avec le portefeuille
        </button>
      </div>

      <WalletPaymentModal
        isOpen={showWalletPayment}
        onClose={() => setShowWalletPayment(false)}
        amount={price}
        description={`Abonnement à ${pronosticName}`}
        onConfirm={handleWalletPayment}
      />
    </div>
  );
};

export default SubscriptionModal;