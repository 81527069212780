import { 
  collection, 
  addDoc,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  increment,
  runTransaction
} from 'firebase/firestore';
import { db } from '../config';
import { generateId } from '../../utils/helpers';
import toast from 'react-hot-toast';

const TAX_RATE = 0.30; // 30% tax rate

export const requestWithdrawal = async (
  userId: string,
  data: {
    amount: number;
    method: 'bank' | 'orange' | 'usdt';
    details: {
      bankAccount?: string;
      bankRouting?: string;
      phoneNumber?: string;
      usdtAddress?: string;
    };
  }
) => {
  try {
    const walletRef = doc(db, 'wallets', userId);
    const withdrawalsRef = collection(db, 'withdrawals');

    await runTransaction(db, async (transaction) => {
      // Check wallet balance
      const walletDoc = await transaction.get(walletRef);
      if (!walletDoc.exists()) {
        throw new Error('Wallet not found');
      }

      const walletData = walletDoc.data();
      if (walletData.balance < data.amount) {
        throw new Error('Insufficient balance');
      }

      // Calculate tax amount
      const taxAmount = data.amount * TAX_RATE;
      const netAmount = data.amount - taxAmount;

      // Get user details
      const userRef = doc(db, 'users', userId);
      const pronosticRef = doc(db, 'pronostics', userId);
      let userDoc = await transaction.get(userRef);
      
      if (!userDoc.exists()) {
        userDoc = await transaction.get(pronosticRef);
      }
      
      if (!userDoc.exists()) {
        throw new Error('User not found');
      }

      const userData = userDoc.data();

      // Create withdrawal request
      const withdrawalRef = await addDoc(withdrawalsRef, {
        userId,
        amount: data.amount,
        netAmount,
        taxAmount,
        method: data.method,
        details: data.details,
        status: 'pending',
        customerName: `${userData.firstName} ${userData.name}`,
        customerEmail: userData.email,
        phoneNumber: data.details.phoneNumber || userData.phone,
        userType: userDoc.ref.path.includes('pronostics') ? 'pronostic' : 'user',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      // Lock withdrawal amount
      transaction.update(walletRef, {
        balance: increment(-data.amount),
        lockedAmount: increment(data.amount),
        updatedAt: serverTimestamp()
      });

      // Create transaction record
      const transactionRef = collection(db, 'transactions');
      await addDoc(transactionRef, {
        userId,
        type: 'withdrawal',
        amount: -data.amount,
        netAmount,
        taxAmount,
        method: data.method,
        status: 'pending',
        reference: withdrawalRef.id,
        customerName: `${userData.firstName} ${userData.name}`,
        customerEmail: userData.email,
        phoneNumber: data.details.phoneNumber || userData.phone,
        createdAt: serverTimestamp()
      });
    });

    toast.success('Demande de retrait envoyée avec succès');
    return true;
  } catch (error: any) {
    console.error('Withdrawal request error:', error);
    throw error;
  }
};