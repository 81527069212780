import { collection, doc, getDoc, getDocs, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../config';
import { AdminPronostic } from './types';

export const getAllPronostics = async (): Promise<AdminPronostic[]> => {
  try {
    const pronosticsRef = collection(db, 'pronostics');
    const snapshot = await getDocs(pronosticsRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as AdminPronostic[];
  } catch (error) {
    console.error('Error getting pronostics:', error);
    throw error;
  }
};

export const updatePronosticStatus = async (pronosticId: string, status: string): Promise<void> => {
  try {
    const pronosticRef = doc(db, 'pronostics', pronosticId);
    await updateDoc(pronosticRef, {
      status,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating pronostic status:', error);
    throw error;
  }
};