import React, { useState, useEffect } from 'react';
import { User, Trophy, Users, Star } from 'lucide-react';
import { getAllPronostics, updatePronosticStatus } from '../../lib/firebase/admin';
import { format } from 'date-fns';
import toast from 'react-hot-toast';

const AdminPronostics = () => {
  const [pronostics, setPronostics] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPronostics();
  }, []);

  const loadPronostics = async () => {
    try {
      const data = await getAllPronostics();
      setPronostics(data);
    } catch (error: any) {
      console.error('Error loading pronostics:', error);
      toast.error(error.message || 'Error loading pronostics');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (pronosticId: string, status: string) => {
    try {
      await updatePronosticStatus(pronosticId, status);
      await loadPronostics();
      toast.success('Pronostic status updated successfully');
    } catch (error: any) {
      console.error('Error updating pronostic status:', error);
      toast.error(error.message || 'Error updating pronostic status');
    }
  };

  if (loading) {
    return <div className="text-center py-8">Loading pronostics...</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Pronostic
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Stats
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Joined
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {pronostics.map((pronostic) => (
              <tr key={pronostic.id}>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <div className="bg-blue-100 rounded-full p-2">
                      <Trophy className="h-5 w-5 text-blue-600" />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {pronostic.name} {pronostic.firstName}
                      </div>
                      <div className="text-sm text-gray-500">
                        {pronostic.email}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center text-sm text-gray-500">
                      <Users className="h-4 w-4 mr-1" />
                      {pronostic.subscribers || 0} subscribers
                    </div>
                    <div className="flex items-center text-sm text-gray-500">
                      <Star className="h-4 w-4 mr-1" />
                      {pronostic.successRate || 0}% success rate
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    pronostic.status === 'active'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {pronostic.status}
                  </span>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {pronostic.createdAt?.toDate ? 
                    format(pronostic.createdAt.toDate(), 'dd/MM/yyyy') :
                    'N/A'
                  }
                </td>
                <td className="px-6 py-4 text-sm">
                  <button
                    onClick={() => handleStatusUpdate(
                      pronostic.id,
                      pronostic.status === 'active' ? 'suspended' : 'active'
                    )}
                    className={`px-3 py-1 rounded-md ${
                      pronostic.status === 'active'
                        ? 'text-red-600 hover:bg-red-50'
                        : 'text-green-600 hover:bg-green-50'
                    }`}
                  >
                    {pronostic.status === 'active' ? 'Suspend' : 'Activate'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminPronostics;