import React, { useState } from 'react';
import { Trash2 } from 'lucide-react';
import { deleteCoupon } from '../lib/firebase/coupons/deleteCoupon';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import toast from 'react-hot-toast';

interface DeleteCouponButtonProps {
  couponId: string;
  pronosticId: string;
  onDelete?: () => void;
}

const DeleteCouponButton: React.FC<DeleteCouponButtonProps> = ({
  couponId,
  pronosticId,
  onDelete
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteCoupon(couponId, pronosticId);
      setShowConfirmation(false);
      onDelete?.();
      toast.success('Coupon supprimé avec succès');
    } catch (error: any) {
      toast.error(error.message || 'Erreur lors de la suppression');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button
        onClick={() => setShowConfirmation(true)}
        className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors"
        title="Supprimer le coupon"
      >
        <Trash2 size={20} />
      </button>

      <DeleteConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleDelete}
        loading={loading}
        title="Supprimer le coupon"
        message="Êtes-vous sûr de vouloir supprimer ce coupon ? Cette action est irréversible."
      />
    </>
  );
};

export default DeleteCouponButton;