import { 
  collection,
  query, 
  orderBy, 
  onSnapshot,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  increment,
  writeBatch,
  addDoc,
  Timestamp
} from 'firebase/firestore';
import { db } from '../config';
import { verifyAdminAccess } from './auth';

export const listenToAllTransactions = (callback: (transactions: any[]) => void) => {
  try {
    const unsubscribePromise = verifyAdminAccess().then(isAdmin => {
      if (!isAdmin) {
        throw new Error('Admin access required');
      }

      // Listen to both payments and withdrawals
      const paymentsQuery = query(
        collection(db, 'payments'),
        orderBy('createdAt', 'desc')
      );

      const withdrawalsQuery = query(
        collection(db, 'withdrawals'),
        orderBy('createdAt', 'desc')
      );

      const unsubPayments = onSnapshot(paymentsQuery, (paymentsSnapshot) => {
        const unsubWithdrawals = onSnapshot(withdrawalsQuery, (withdrawalsSnapshot) => {
          // Format payments
          const payments = paymentsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            type: 'deposit',
            createdAt: doc.data().createdAt?.toDate(),
            updatedAt: doc.data().updatedAt?.toDate(),
            processedAt: doc.data().processedAt?.toDate()
          }));

          // Format withdrawals
          const withdrawals = withdrawalsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            type: 'withdrawal',
            createdAt: doc.data().createdAt?.toDate(),
            updatedAt: doc.data().updatedAt?.toDate(),
            processedAt: doc.data().processedAt?.toDate()
          }));

          // Combine and sort by date
          const allTransactions = [...payments, ...withdrawals].sort((a, b) => 
            b.createdAt.getTime() - a.createdAt.getTime()
          );

          callback(allTransactions);
        });

        return () => {
          unsubWithdrawals();
        };
      });

      return () => {
        unsubPayments();
      };
    });

    return () => {
      unsubscribePromise.then(unsubscribe => unsubscribe?.());
    };
  } catch (error) {
    console.error('Error in transactions listener:', error);
    return () => {};
  }
};

export const processTransaction = async (
  transactionId: string,
  type: 'deposit' | 'withdrawal',
  status: 'completed' | 'rejected',
  adminId: string
) => {
  try {
    if (!await verifyAdminAccess()) {
      throw new Error('Admin access required');
    }

    const batch = writeBatch(db);
    const now = Timestamp.now();

    // Get transaction document
    const collectionName = type === 'deposit' ? 'payments' : 'withdrawals';
    const transactionRef = doc(db, collectionName, transactionId);
    const transactionSnap = await getDoc(transactionRef);
    
    if (!transactionSnap.exists()) {
      throw new Error('Transaction not found');
    }

    const transactionData = transactionSnap.data();

    // For crypto deposits, check if payment is confirmed
    if (type === 'deposit' && transactionData.method === 'crypto') {
      if (!['pending_confirmation', 'pending'].includes(transactionData.status)) {
        throw new Error('Invalid transaction status');
      }
    }

    const walletRef = doc(db, 'wallets', transactionData.userId);
    const walletSnap = await getDoc(walletRef);

    if (!walletSnap.exists()) {
      throw new Error('Wallet not found');
    }

    // Update transaction status
    batch.update(transactionRef, {
      status,
      processedAt: now,
      processedBy: adminId,
      updatedAt: now
    });

    // Handle wallet balance updates
    if (status === 'completed') {
      if (type === 'deposit') {
        // Update wallet balance
        batch.update(walletRef, {
          balance: increment(transactionData.amount),
          updatedAt: now
        });

        // Create transaction record
        const transactionRecordRef = doc(collection(db, 'transactions'));
        batch.set(transactionRecordRef, {
          userId: transactionData.userId,
          type: 'deposit',
          method: transactionData.method,
          amount: transactionData.amount,
          status: 'completed',
          reference: transactionId,
          description: `Dépôt ${transactionData.method === 'crypto' ? 'USDT' : transactionData.method}`,
          createdAt: now,
          processedAt: now,
          processedBy: adminId
        });

        // Handle subscription if applicable
        if (transactionData.type === 'subscription' && transactionData.pronosticId) {
          const subscriptionRef = doc(collection(db, 'subscriptions'));
          const endDate = new Date();
          endDate.setMonth(endDate.getMonth() + 1);

          batch.set(subscriptionRef, {
            userId: transactionData.userId,
            pronosticId: transactionData.pronosticId,
            type: 'special',
            startDate: now,
            endDate: Timestamp.fromDate(endDate),
            amount: transactionData.amount,
            status: 'active',
            createdAt: now,
            updatedAt: now
          });

          // Update pronostic stats
          const pronosticRef = doc(db, 'pronostics', transactionData.pronosticId);
          batch.update(pronosticRef, {
            subscribers: increment(1),
            balance: increment(transactionData.amount),
            updatedAt: now
          });
        }
      } else {
        // For withdrawals, remove locked amount
        batch.update(walletRef, {
          lockedAmount: increment(-transactionData.amount),
          updatedAt: now
        });
      }
    } else if (status === 'rejected') {
      if (type === 'withdrawal') {
        // Return locked amount to available balance
        batch.update(walletRef, {
          balance: increment(transactionData.amount),
          lockedAmount: increment(-transactionData.amount),
          updatedAt: now
        });
      }
    }

    await batch.commit();
    return true;
  } catch (error) {
    console.error('Error processing transaction:', error);
    throw error;
  }
};