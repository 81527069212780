import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { User, Trophy, MessageCircle, Users, Star, DollarSign } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/Tabs';
import BackButton from '../components/BackButton';
import CouponCard from '../components/CouponCard';
import SubscriptionModal from '../components/payment/SubscriptionModal';
import { useAuthStore } from '../store/authStore';
import { usePronostic } from '../hooks/usePronostic';
import { useCoupons } from '../hooks/useCoupons';
import { useWallet } from '../hooks/useWallet';
import { formatDate } from '../lib/utils/helpers';
import { getSubscriptionDetails, listenToSubscriberCount } from '../lib/firebase/subscriptions';
import toast from 'react-hot-toast';

const PronosticProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userType, userData } = useAuthStore();
  const { pronostic, loading: pronosticLoading } = usePronostic(id);
  const { coupons, loading: couponsLoading } = useCoupons(id);
  const { wallet } = useWallet(userData?.id);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [subscription, setSubscription] = useState<any>(null);
  const [subscriberCount, setSubscriberCount] = useState(0);

  useEffect(() => {
    if (id) {
      const unsubscribe = listenToSubscriberCount(id, (count) => {
        setSubscriberCount(count);
      });

      return () => unsubscribe();
    }
  }, [id]);

  useEffect(() => {
    if (userData?.id && id) {
      loadSubscription();
    }
  }, [userData?.id, id]);

  const loadSubscription = async () => {
    try {
      const details = await getSubscriptionDetails(userData?.id || '', id || '');
      setSubscription(details);
    } catch (error) {
      console.error('Error loading subscription:', error);
    }
  };

  const handleSubscribe = () => {
    if (!userData) {
      toast.error('Vous devez être connecté pour vous abonner');
      navigate('/connexion-utilisateur');
      return;
    }

    if (!wallet || wallet.balance < (pronostic?.specialSubscriptionPrice || 0)) {
      toast.error('Solde insuffisant. Veuillez recharger votre portefeuille.');
      navigate('/wallet');
      return;
    }

    setShowSubscriptionModal(true);
  };

  if (pronosticLoading) {
    return (
      <div className="max-w-6xl mx-auto px-4">
        <BackButton to="/dashboard" />
        <div className="text-center py-12">Chargement...</div>
      </div>
    );
  }

  if (!pronostic) {
    return (
      <div className="max-w-6xl mx-auto px-4">
        <BackButton to="/dashboard" />
        <div className="text-center py-12">
          <h2 className="text-2xl font-semibold mb-2">Pronostiqueur non trouvé</h2>
          <p className="text-gray-600">Ce pronostiqueur n'existe pas ou n'est plus disponible.</p>
        </div>
      </div>
    );
  }

  const isSubscribed = subscription?.status === 'active';

  return (
    <div className="max-w-6xl mx-auto px-4">
      <BackButton to="/dashboard" />
      
      <div className="bg-white rounded-lg shadow-md p-8 mb-8">
        <div className="flex flex-col md:flex-row md:items-start md:justify-between">
          <div className="flex items-center mb-6 md:mb-0">
            <div className="bg-blue-100 p-4 rounded-full">
              <User size={40} className="text-blue-600" />
            </div>
            <div className="ml-4">
              <h1 className="text-2xl font-bold">{pronostic.name} {pronostic.firstName}</h1>
              <p className="text-gray-600">
                Membre depuis {formatDate(pronostic.createdAt)}
              </p>
            </div>
          </div>
          
          <div className="flex flex-col items-end">
            <div className="flex items-center mb-2">
              <Trophy size={20} className="text-yellow-500 mr-2" />
              <span className="font-semibold">{pronostic.successRate}% de réussite</span>
            </div>
            <div className="flex items-center mb-2">
              <Users size={20} className="text-blue-600 mr-2" />
              <span>{subscriberCount} abonnés</span>
            </div>
            <div className="flex items-center mb-2">
              <DollarSign size={20} className="text-green-600 mr-2" />
              <span>{pronostic.specialSubscriptionPrice} FCFA/mois</span>
            </div>
          </div>
        </div>

        {pronostic.communityLink && (
          <div className="mt-6 p-4 bg-gray-50 rounded-lg">
            <h3 className="font-semibold mb-2">Communauté</h3>
            <a
              href={pronostic.communityLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-blue-600 hover:text-blue-700"
            >
              <MessageCircle size={20} className="mr-2" />
              Rejoindre la communauté
            </a>
          </div>
        )}

        {userType === 'user' && (
          <div className="mt-6">
            {isSubscribed ? (
              <div className="flex items-center justify-between p-4 bg-green-50 rounded-lg">
                <div className="flex items-center">
                  <Star className="w-5 h-5 text-green-600 mr-2" />
                  <span className="font-medium text-green-800">Abonné</span>
                </div>
              </div>
            ) : (
              <button
                onClick={handleSubscribe}
                className="w-full py-3 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition flex items-center justify-center"
              >
                <Star className="w-5 h-5 mr-2" />
                S'abonner pour {pronostic.specialSubscriptionPrice} FCFA/mois
              </button>
            )}
            <p className="text-sm text-gray-600 text-center mt-2">
              Accédez à tous les coupons et rejoignez la communauté
            </p>
          </div>
        )}
      </div>

      <Tabs defaultValue="coupons">
        <TabsList>
          <TabsTrigger value="coupons">Coupons actifs</TabsTrigger>
          <TabsTrigger value="history">Historique</TabsTrigger>
          <TabsTrigger value="stats">Statistiques</TabsTrigger>
        </TabsList>

        <TabsContent value="coupons">
          {couponsLoading ? (
            <div className="text-center py-8">Chargement des coupons...</div>
          ) : (
            <div className="grid gap-6 md:grid-cols-2">
              {coupons.active.map((coupon) => (
                <CouponCard 
                  key={coupon.id} 
                  coupon={coupon} 
                  showPronosticInfo={false}
                />
              ))}
              {coupons.active.length === 0 && (
                <div className="col-span-2 text-center py-8 text-gray-500">
                  Aucun coupon actif pour le moment
                </div>
              )}
            </div>
          )}
        </TabsContent>

        <TabsContent value="history">
          <div className="grid gap-6 md:grid-cols-2">
            {coupons.history.map((coupon) => (
              <CouponCard 
                key={coupon.id} 
                coupon={coupon} 
                showPronosticInfo={false}
              />
            ))}
            {coupons.history.length === 0 && (
              <div className="col-span-2 text-center py-8 text-gray-500">
                Aucun historique disponible
              </div>
            )}
          </div>
        </TabsContent>

        <TabsContent value="stats">
          <div className="bg-white rounded-lg shadow p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-sm text-gray-600">Taux de réussite</div>
                <div className="text-2xl font-bold text-blue-600">
                  {pronostic.successRate}%
                </div>
                <div className="text-sm text-gray-500">
                  {pronostic.wonCoupons} gagnés sur {pronostic.totalCoupons} coupons
                </div>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-sm text-gray-600">Abonnés</div>
                <div className="text-2xl font-bold text-blue-600">
                  {subscriberCount}
                </div>
                <div className="text-sm text-gray-500">
                  Membres de la communauté
                </div>
              </div>
            </div>
          </div>
        </TabsContent>
      </Tabs>

      {showSubscriptionModal && (
        <SubscriptionModal
          isOpen={showSubscriptionModal}
          onClose={() => setShowSubscriptionModal(false)}
          pronosticId={pronostic.id}
          price={pronostic.specialSubscriptionPrice}
          pronosticName={`${pronostic.firstName} ${pronostic.name}`}
        />
      )}
    </div>
  );
};

export default PronosticProfile;