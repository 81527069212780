import React from 'react';
import { ArrowUpDown } from 'lucide-react';
import SportFilter from '../SportFilter';
import CouponCard from '../CouponCard';

interface CouponsListProps {
  loading: boolean;
  error: string | null;
  coupons: any[];
  selectedSport: string | null;
  sortBy: 'odds' | 'winrate' | 'popular';
  onSelectSport: (sport: string | null) => void;
  onChangeSortBy: () => void;
}

const CouponsList: React.FC<CouponsListProps> = ({
  loading,
  error,
  coupons,
  selectedSport,
  sortBy,
  onSelectSport,
  onChangeSortBy
}) => {
  return (
    <>
      <div className="flex flex-wrap gap-2 mb-4">
        <SportFilter
          selectedSport={selectedSport}
          onSelectSport={onSelectSport}
        />
        <button
          onClick={onChangeSortBy}
          className="px-4 py-2 text-sm bg-white border rounded-lg hover:bg-gray-50 flex items-center"
        >
          <ArrowUpDown className="w-4 h-4 mr-2" />
          Trier par {
            sortBy === 'odds' 
              ? 'cote' 
              : sortBy === 'winrate' 
              ? 'taux de réussite' 
              : 'popularité'
          }
        </button>
      </div>

      {loading ? (
        <div className="text-center py-8">Chargement des coupons...</div>
      ) : error ? (
        <div className="text-center py-8 text-red-600">{error}</div>
      ) : coupons.length > 0 ? (
        <div className="grid gap-4 md:grid-cols-2">
          {coupons.map((coupon) => (
            <CouponCard key={coupon.id} coupon={coupon} />
          ))}
        </div>
      ) : (
        <div className="text-center py-8 text-gray-500">
          Aucun coupon disponible pour le moment
        </div>
      )}
    </>
  );
};

export default CouponsList;