import React from 'react';
import { useAuthStore } from '../store/authStore';
import { User, Calendar, Trophy, Bell } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import BackButton from '../components/BackButton';
import RenewalBanner from '../components/RenewalBanner';

const UserProfile = () => {
  const { userData } = useAuthStore();

  return (
    <div className="max-w-4xl mx-auto">
      <BackButton to="/dashboard" />
      
      <div className="bg-white rounded-xl shadow-md p-8">
        <div className="flex items-center mb-8">
          <div className="bg-blue-100 p-4 rounded-full">
            <User className="h-8 w-8 text-blue-600" />
          </div>
          <div className="ml-4">
            <h1 className="text-2xl font-bold">{userData?.name}</h1>
            <p className="text-gray-600">{userData?.email}</p>
          </div>
        </div>

        <RenewalBanner />

        <div className="space-y-6">
          <div className="border-t pt-6">
            <h2 className="text-lg font-semibold mb-4">Abonnement</h2>
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center text-gray-600">
                  <Calendar className="h-5 w-5 mr-2" />
                  <span>Date d'expiration:</span>
                </div>
                <span className="font-medium">
                  {userData?.subscriptionEnd ? 
                    format(new Date(userData.subscriptionEnd), 'dd MMMM yyyy', { locale: fr }) :
                    'Non abonné'}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center text-gray-600">
                  <Bell className="h-5 w-5 mr-2" />
                  <span>Status:</span>
                </div>
                <span className={`font-medium ${
                  userData?.subscriptionStatus === 'premium'
                    ? 'text-green-600' 
                    : 'text-red-600'
                }`}>
                  {userData?.subscriptionStatus === 'premium'
                    ? 'Premium' 
                    : 'Gratuit'}
                </span>
              </div>
            </div>
          </div>

          <div className="border-t pt-6">
            <h2 className="text-lg font-semibold mb-4">Historique des coupons</h2>
            <div className="grid gap-4 md:grid-cols-2">
              <div className="bg-green-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Trophy className="h-5 w-5 text-green-600 mr-2" />
                    <span className="text-green-800">Coupons gagnés</span>
                  </div>
                  <span className="text-xl font-bold text-green-600">
                    {userData?.wonCoupons || 0}
                  </span>
                </div>
              </div>
              <div className="bg-red-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Trophy className="h-5 w-5 text-red-600 mr-2" />
                    <span className="text-red-800">Coupons perdus</span>
                  </div>
                  <span className="text-xl font-bold text-red-600">
                    {userData?.lostCoupons || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;