import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export function formatDate(date: any): string {
  try {
    if (date?.toDate) {
      return format(date.toDate(), 'MMMM yyyy', { locale: fr });
    }
    if (date) {
      return format(new Date(date), 'MMMM yyyy', { locale: fr });
    }
    return 'Date non disponible';
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Date non disponible';
  }
}

export function formatDateTime(date: any): string {
  try {
    if (date?.toDate) {
      return format(date.toDate(), 'dd/MM/yyyy HH:mm', { locale: fr });
    }
    if (date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm', { locale: fr });
    }
    return 'Date non disponible';
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Date non disponible';
  }
}

export function formatAmount(amount: number): string {
  return amount.toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'XOF'
  });
}

export function generateId(length: number = 16): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

export function generatePaymentReference(): string {
  const timestamp = Date.now().toString();
  const random = Math.random().toString(36).substring(2, 8).toUpperCase();
  return `PAY-${timestamp}-${random}`;
}

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout;

  return function executedFunction(...args: Parameters<T>) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function normalizeSearchTerm(term: string): string {
  return term.toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();
}