import { useState, useEffect } from 'react';
import { useAuthStore } from '../store/authStore';
import { checkSubscriptionStatus } from '../lib/firebase/subscriptions';
import { differenceInDays } from 'date-fns';

export function useSubscription() {
  const { userData } = useAuthStore();
  const [daysLeft, setDaysLeft] = useState<number>(0);
  const [shouldRenew, setShouldRenew] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkStatus = async () => {
      if (!userData?.id) {
        setIsActive(false);
        setLoading(false);
        return;
      }

      try {
        const hasSubscription = await checkSubscriptionStatus(userData.id);
        setIsActive(hasSubscription);
        setIsNewUser(!userData.subscriptionEnd);

        if (userData.subscriptionEnd) {
          const endDate = new Date(userData.subscriptionEnd);
          const days = differenceInDays(endDate, new Date());
          setDaysLeft(days);
          setShouldRenew(days <= 7);
        } else {
          setDaysLeft(0);
          setShouldRenew(!hasSubscription);
        }
      } catch (error) {
        console.error('Error checking subscription:', error);
      } finally {
        setLoading(false);
      }
    };

    checkStatus();
  }, [userData]);

  return {
    daysLeft,
    shouldRenew,
    isActive,
    isNewUser,
    loading
  };
}