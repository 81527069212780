import React from 'react';
import { Link } from 'react-router-dom';
import { Trophy } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-white border-t py-8">
      <div className="container mx-auto px-4">
        <div className="flex items-center mb-4">
          <Trophy className="h-6 w-6 text-blue-600 mr-2" />
          <span className="text-xl font-bold">CouponXBet</span>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-8">
          <div>
            <h3 className="font-semibold mb-4">À propos</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="text-gray-600 hover:text-gray-900">
                  À propos de nous
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-600 hover:text-gray-900">
                  Conditions d'utilisation
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-600 hover:text-gray-900">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-semibold mb-4">Liens utiles</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/devenir-pronostiqueur" className="text-gray-600 hover:text-gray-900">
                  Devenir Pronostiqueur
                </Link>
              </li>
              <li>
                <Link to="/admin/login" className="text-gray-400 hover:text-gray-600">
                  Staff Login
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-semibold mb-4">Contact</h3>
            <p className="text-gray-600">
              Email:{' '}
              <a 
                href="mailto:couponxbet@nabisstech.online"
                className="text-blue-600 hover:text-blue-700"
              >
                couponxbet@nabisstech.online
              </a>
            </p>
          </div>
        </div>

        <div className="text-center text-sm text-gray-500 border-t pt-8">
          <p>© {new Date().getFullYear()} CouponXBet. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;