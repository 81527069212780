import { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, increment, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase/config';
import { Pronostic } from '../types';
import toast from 'react-hot-toast';

export function usePronostic(pronosticId?: string) {
  const [pronostic, setPronostic] = useState<Pronostic | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (pronosticId) {
      loadPronosticData();
    }
  }, [pronosticId]);

  const loadPronosticData = async () => {
    if (!pronosticId) return;
    
    try {
      setLoading(true);
      setError(null);
      
      const pronosticRef = doc(db, 'pronostics', pronosticId);
      const pronosticSnap = await getDoc(pronosticRef);
      
      if (!pronosticSnap.exists()) {
        throw new Error('Pronostic not found');
      }

      setPronostic({
        id: pronosticSnap.id,
        ...pronosticSnap.data()
      } as Pronostic);
    } catch (error: any) {
      console.error('Error loading pronostic:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const subscribeToPronostic = async (pronosticId: string, price: number) => {
    try {
      const pronosticRef = doc(db, 'pronostics', pronosticId);
      
      // Update pronostic subscribers count
      await updateDoc(pronosticRef, {
        subscribers: increment(1),
        updatedAt: serverTimestamp()
      });

      // Update local state
      setPronostic(prev => prev ? {
        ...prev,
        subscribers: (prev.subscribers || 0) + 1
      } : null);

      toast.success('Abonnement réussi !');
    } catch (error: any) {
      console.error('Error subscribing to pronostic:', error);
      throw error;
    }
  };

  return {
    pronostic,
    loading,
    error,
    subscribeToPronostic,
    refresh: loadPronosticData
  };
}