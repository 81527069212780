import { 
  doc, 
  collection,
  getDoc,
  addDoc,
  updateDoc,
  serverTimestamp,
  increment,
  runTransaction
} from 'firebase/firestore';
import { db } from './config';
import { generateId } from '../utils/helpers';
import toast from 'react-hot-toast';

export const processWalletPayment = async (
  userId: string,
  amount: number,
  description: string,
  paymentFunction: () => Promise<void>
) => {
  try {
    const walletRef = doc(db, 'wallets', userId);

    await runTransaction(db, async (transaction) => {
      const walletDoc = await transaction.get(walletRef);
      if (!walletDoc.exists()) {
        throw new Error('Wallet not found');
      }

      const walletData = walletDoc.data();
      if (walletData.balance < amount) {
        throw new Error('Insufficient balance');
      }

      // Update wallet balance
      transaction.update(walletRef, {
        balance: increment(-amount),
        updatedAt: serverTimestamp()
      });

      // Create transaction record
      const transactionRef = collection(db, 'transactions');
      await addDoc(transactionRef, {
        userId,
        type: 'payment',
        amount: -amount,
        status: 'completed',
        description,
        createdAt: serverTimestamp()
      });

      // Execute the provided payment function
      await paymentFunction();
    });

    return true;
  } catch (error: any) {
    console.error('Payment error:', error);
    throw error;
  }
};

export const getWalletBalance = async (userId: string): Promise<number> => {
  try {
    const walletRef = doc(db, 'wallets', userId);
    const walletSnap = await getDoc(walletRef);
    
    if (!walletSnap.exists()) {
      // Create wallet if it doesn't exist
      await updateDoc(walletRef, {
        balance: 0,
        lockedAmount: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      return 0;
    }

    return walletSnap.data().balance || 0;
  } catch (error: any) {
    console.error('Error getting balance:', error);
    throw error;
  }
};

export const createWalletTransaction = async (
  userId: string,
  data: {
    type: 'deposit' | 'withdrawal' | 'payment';
    amount: number;
    description?: string;
    reference?: string;
  }
) => {
  try {
    const transactionRef = collection(db, 'transactions');
    await addDoc(transactionRef, {
      userId,
      ...data,
      status: 'completed',
      createdAt: serverTimestamp()
    });
    return true;
  } catch (error: any) {
    console.error('Transaction error:', error);
    throw error;
  }
};