import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserCheck } from 'lucide-react';
import { registerPronostic } from '../lib/firebase/auth';
import toast from 'react-hot-toast';

const PronosticRegister = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    firstName: '',
    email: '',
    phone: '',
    birthDate: '',
    country: '',
    password: '',
    confirmPassword: '',
    communityLink: '',
    specialSubscriptionPrice: '',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      toast.error('Les mots de passe ne correspondent pas');
      return;
    }

    try {
      setLoading(true);
      await registerPronostic({
        ...formData,
        specialSubscriptionPrice: Number(formData.specialSubscriptionPrice),
        subscribers: 0,
        successRate: 0,
        totalCoupons: 0,
        wonCoupons: 0,
        balance: 0
      });
      toast.success('Compte pronostiqueur créé avec succès');
      navigate('/pronostic-dashboard');
    } catch (error: any) {
      toast.error(error.message || 'Erreur lors de l\'inscription');
      console.error('Registration error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="max-w-md mx-auto">
      <div className="text-center mb-8">
        <UserCheck size={48} className="mx-auto text-green-600 mb-4" />
        <h2 className="text-2xl font-bold">Devenir Pronostiqueur</h2>
        <p className="text-gray-600 mt-2">Partagez votre expertise et développez votre communauté</p>
      </div>
      
      <div className="bg-white rounded-xl shadow-md p-8">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Nom</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="input-field"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Prénom</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="input-field"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="input-field"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Téléphone</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="input-field"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Date de naissance</label>
            <input
              type="date"
              name="birthDate"
              value={formData.birthDate}
              onChange={handleChange}
              className="input-field"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Pays</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="input-field"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Lien communauté (Telegram/WhatsApp)</label>
            <input
              type="url"
              name="communityLink"
              value={formData.communityLink}
              onChange={handleChange}
              className="input-field"
              placeholder="https://"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Prix abonnement spécial (FCFA)</label>
            <input
              type="number"
              name="specialSubscriptionPrice"
              value={formData.specialSubscriptionPrice}
              onChange={handleChange}
              className="input-field"
              min="0"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Mot de passe</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="input-field"
              required
              minLength={6}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Confirmer le mot de passe</label>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="input-field"
              required
              minLength={6}
            />
          </div>

          <button 
            type="submit" 
            className="w-full bg-green-600 text-white rounded-lg py-2 hover:bg-green-700 transition"
            disabled={loading}
          >
            {loading ? 'Inscription en cours...' : 'S\'inscrire comme pronostiqueur'}
          </button>
        </form>

        <div className="mt-6 text-center">
          <p className="text-sm text-gray-600">
            Déjà un compte ?{' '}
            <Link to="/connexion-pronostiqueur" className="text-green-600 hover:text-green-700 font-medium">
              Se connecter
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PronosticRegister;