import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings, Link as LinkIcon, DollarSign } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { updatePronosticSettings } from '../lib/firebase/pronostics';
import BackButton from '../components/BackButton';
import toast from 'react-hot-toast';

const PronosticSettings = () => {
  const navigate = useNavigate();
  const { userData, updateUserData } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    communityLink: userData?.communityLink || '',
    specialSubscriptionPrice: userData?.specialSubscriptionPrice || 0
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userData?.id) return;

    try {
      setLoading(true);
      await updatePronosticSettings(userData.id, formData);
      updateUserData(formData);
      toast.success('Paramètres mis à jour avec succès');
    } catch (error: any) {
      console.error('Settings update error:', error);
      toast.error(error.message || 'Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4">
      <BackButton to="/pronostic-dashboard" />
      
      <div className="bg-white rounded-xl shadow-md p-8">
        <div className="flex items-center mb-6">
          <Settings className="h-8 w-8 text-blue-600 mr-3" />
          <h1 className="text-2xl font-bold">Paramètres du compte</h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Lien communauté (Telegram/WhatsApp)
            </label>
            <div className="mt-1 relative">
              <input
                type="url"
                className="input-field pl-10"
                value={formData.communityLink}
                onChange={(e) => setFormData({ ...formData, communityLink: e.target.value })}
                placeholder="https://"
              />
              <LinkIcon className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Prix abonnement spécial (FCFA)
            </label>
            <div className="mt-1 relative">
              <input
                type="number"
                min="0"
                step="100"
                className="input-field pl-10"
                value={formData.specialSubscriptionPrice}
                onChange={(e) => setFormData({ 
                  ...formData, 
                  specialSubscriptionPrice: Number(e.target.value)
                })}
                required
              />
              <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>

          <button
            type="submit"
            className="w-full btn-primary"
            disabled={loading}
          >
            {loading ? 'Mise à jour...' : 'Enregistrer les modifications'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PronosticSettings;