import React, { useState, useEffect } from 'react';
import { Wallet, ArrowUpRight, ArrowDownRight } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { useWallet } from '../hooks/useWallet';
import { requestWithdrawal } from '../lib/firebase/withdrawals';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase/config';
import BackButton from '../components/BackButton';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/Tabs';
import WithdrawalForm from '../components/wallet/WithdrawalForm';
import TransactionHistory from '../components/wallet/TransactionHistory';
import toast from 'react-hot-toast';

const PronosticWallet = () => {
  const { userData } = useAuthStore();
  const { wallet, transactions, loading, error, refresh } = useWallet(userData?.id || '');
  const [isProcessing, setIsProcessing] = useState(false);
  const [realTimeBalance, setRealTimeBalance] = useState(0);

  useEffect(() => {
    if (!userData?.id) return;

    // Set up real-time listener for wallet balance
    const unsubscribe = onSnapshot(
      doc(db, 'wallets', userData.id),
      (doc) => {
        if (doc.exists()) {
          setRealTimeBalance(doc.data().balance || 0);
        }
      },
      (error) => {
        console.error('Error listening to wallet:', error);
        toast.error('Erreur lors de la mise à jour du solde');
      }
    );

    return () => unsubscribe();
  }, [userData?.id]);

  const handleWithdrawal = async (data: {
    amount: number;
    method: 'bank' | 'orange' | 'usdt';
    details: {
      bankAccount?: string;
      bankRouting?: string;
      phoneNumber?: string;
      usdtAddress?: string;
    };
  }) => {
    if (!userData?.id) return;

    try {
      setIsProcessing(true);
      await requestWithdrawal(userData.id, data);
      await refresh();
      toast.success('Demande de retrait envoyée avec succès');
    } catch (error: any) {
      console.error('Withdrawal error:', error);
      toast.error(error.message || 'Erreur lors de la demande de retrait');
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center py-8">Chargement du portefeuille...</div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4">
      <BackButton to="/pronostic-dashboard" />
      
      <div className="bg-white rounded-xl shadow-md p-6 md:p-8 mb-8">
        <div className="flex items-center mb-6">
          <Wallet className="h-8 w-8 text-blue-600 mr-3" />
          <div>
            <h1 className="text-2xl font-bold">Mon Portefeuille</h1>
            <p className="text-gray-600">Gérez vos revenus d'abonnements</p>
          </div>
        </div>

        <div className="bg-gray-50 p-6 rounded-lg mb-6">
          <div className="text-sm text-gray-600">Solde disponible</div>
          <div className="text-3xl font-bold text-blue-600">
            {realTimeBalance.toLocaleString()} FCFA
          </div>
        </div>

        <Tabs defaultValue="withdraw" className="space-y-6">
          <TabsList className="w-full flex space-x-2 overflow-x-auto">
            <TabsTrigger value="withdraw" className="flex-1">Retrait</TabsTrigger>
            <TabsTrigger value="history" className="flex-1">Historique</TabsTrigger>
          </TabsList>

          <TabsContent value="withdraw">
            <WithdrawalForm
              maxAmount={realTimeBalance}
              onSubmit={handleWithdrawal}
              isProcessing={isProcessing}
            />
          </TabsContent>

          <TabsContent value="history">
            <TransactionHistory 
              transactions={transactions}
            />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default PronosticWallet;