import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Users, 
  TrendingUp, 
  DollarSign, 
  FileText, 
  Shield, 
  LogOut,
  BarChart,
  Settings,
  Bell
} from 'lucide-react';
import { signOut } from '../lib/firebase/auth';
import { useAuthStore } from '../store/authStore';
import { 
  AdminUsers,
  AdminPronostics,
  AdminBets,
  AdminCoupons,
  AdminTransactions 
} from '../components/admin';
import toast from 'react-hot-toast';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { logout } = useAuthStore();
  const [activeTab, setActiveTab] = useState('overview');

  const handleLogout = async () => {
    try {
      await signOut();
      logout();
      navigate('/admin/login');
      toast.success('Déconnexion réussie');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Erreur lors de la déconnexion');
    }
  };

  const stats = {
    users: {
      total: 1250,
      active: 980,
      premium: 450
    },
    pronostics: {
      total: 45,
      active: 38,
      topPerformer: 'John Doe'
    },
    transactions: {
      total: 125000,
      pending: 15000,
      today: 5000
    },
    bets: {
      active: 78,
      completed: 156,
      volume: 250000
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold">Users</h3>
                <Users className="text-blue-600" size={24} />
              </div>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-600">Total</span>
                  <span className="font-semibold">{stats.users.total}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Active</span>
                  <span className="font-semibold">{stats.users.active}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Premium</span>
                  <span className="font-semibold">{stats.users.premium}</span>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold">Pronostics</h3>
                <TrendingUp className="text-green-600" size={24} />
              </div>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-600">Total</span>
                  <span className="font-semibold">{stats.pronostics.total}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Active</span>
                  <span className="font-semibold">{stats.pronostics.active}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Top Performer</span>
                  <span className="font-semibold">{stats.pronostics.topPerformer}</span>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold">Transactions</h3>
                <DollarSign className="text-purple-600" size={24} />
              </div>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-600">Total</span>
                  <span className="font-semibold">{stats.transactions.total} FCFA</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Pending</span>
                  <span className="font-semibold">{stats.transactions.pending} FCFA</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Today</span>
                  <span className="font-semibold">{stats.transactions.today} FCFA</span>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold">Bets</h3>
                <FileText className="text-yellow-600" size={24} />
              </div>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-600">Active</span>
                  <span className="font-semibold">{stats.bets.active}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Completed</span>
                  <span className="font-semibold">{stats.bets.completed}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Volume</span>
                  <span className="font-semibold">{stats.bets.volume} FCFA</span>
                </div>
              </div>
            </div>
          </div>
        );
      case 'users':
        return <AdminUsers />;
      case 'pronostics':
        return <AdminPronostics />;
      case 'transactions':
        return <AdminTransactions />;
      case 'bets':
        return <AdminBets />;
      case 'coupons':
        return <AdminCoupons />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Sidebar */}
      <div className="fixed inset-y-0 left-0 w-64 bg-white shadow-lg">
        <div className="flex items-center justify-center h-16 border-b">
          <Shield className="w-8 h-8 text-blue-600 mr-2" />
          <span className="text-xl font-bold">Admin Panel</span>
        </div>

        <nav className="mt-6">
          <div className="px-4 mb-4 text-xs font-semibold text-gray-500 uppercase">
            Main
          </div>
          <button
            onClick={() => setActiveTab('overview')}
            className={`w-full flex items-center px-4 py-3 ${
              activeTab === 'overview' ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <BarChart className="w-5 h-5 mr-3" />
            Overview
          </button>
          <button
            onClick={() => setActiveTab('users')}
            className={`w-full flex items-center px-4 py-3 ${
              activeTab === 'users' ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <Users className="w-5 h-5 mr-3" />
            Users
          </button>
          <button
            onClick={() => setActiveTab('pronostics')}
            className={`w-full flex items-center px-4 py-3 ${
              activeTab === 'pronostics' ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <TrendingUp className="w-5 h-5 mr-3" />
            Pronostics
          </button>

          <div className="px-4 mt-6 mb-4 text-xs font-semibold text-gray-500 uppercase">
            Operations
          </div>
          <button
            onClick={() => setActiveTab('transactions')}
            className={`w-full flex items-center px-4 py-3 ${
              activeTab === 'transactions' ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <DollarSign className="w-5 h-5 mr-3" />
            Transactions
          </button>
          <button
            onClick={() => setActiveTab('bets')}
            className={`w-full flex items-center px-4 py-3 ${
              activeTab === 'bets' ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <FileText className="w-5 h-5 mr-3" />
            Bets
          </button>
          <button
            onClick={() => setActiveTab('coupons')}
            className={`w-full flex items-center px-4 py-3 ${
              activeTab === 'coupons' ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <FileText className="w-5 h-5 mr-3" />
            Coupons
          </button>

          <div className="absolute bottom-0 left-0 right-0 border-t">
            <button
              onClick={handleLogout}
              className="w-full flex items-center px-4 py-3 text-red-600 hover:bg-red-50"
            >
              <LogOut className="w-5 h-5 mr-3" />
              Logout
            </button>
          </div>
        </nav>
      </div>

      {/* Main Content */}
      <div className="ml-64 p-8">
        <div className="mb-8">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">
              {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
            </h1>
            <div className="flex items-center space-x-4">
              <button className="p-2 text-gray-600 hover:bg-gray-100 rounded-full">
                <Bell size={20} />
              </button>
              <button className="p-2 text-gray-600 hover:bg-gray-100 rounded-full">
                <Settings size={20} />
              </button>
            </div>
          </div>
        </div>

        {renderContent()}
      </div>
    </div>
  );
};

export default AdminDashboard;