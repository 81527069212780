import { 
  collection, 
  query, 
  where, 
  getDocs,
  orderBy,
  QueryConstraint,
  Timestamp
} from 'firebase/firestore';
import { db } from '../config';
import { Coupon } from '../../types';

export const searchCoupons = async (
  filters: {
    sport?: string;
    minOdds?: number;
    maxOdds?: number;
    dateRange?: { start: Date; end: Date };
    pronosticId?: string;
  } = {},
  sortBy: 'date' | 'odds' = 'date',
  sortOrder: 'asc' | 'desc' = 'desc'
): Promise<Coupon[]> => {
  try {
    const constraints: QueryConstraint[] = [
      where('status', '==', 'active')
    ];

    if (filters.sport) {
      constraints.push(where('sport', '==', filters.sport));
    }

    if (filters.pronosticId) {
      constraints.push(where('pronosticId', '==', filters.pronosticId));
    }

    if (filters.dateRange) {
      constraints.push(
        where('startDate', '>=', Timestamp.fromDate(filters.dateRange.start)),
        where('startDate', '<=', Timestamp.fromDate(filters.dateRange.end))
      );
    }

    constraints.push(
      orderBy(sortBy === 'date' ? 'startDate' : 'odds', sortOrder)
    );

    const q = query(collection(db, 'coupons'), ...constraints);
    const snapshot = await getDocs(q);
    let coupons = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      startDate: doc.data().startDate.toDate(),
      endDate: doc.data().endDate.toDate(),
      createdAt: doc.data().createdAt?.toDate(),
      updatedAt: doc.data().updatedAt?.toDate()
    })) as Coupon[];

    // Apply client-side filters
    if (filters.minOdds !== undefined) {
      coupons = coupons.filter(coupon => coupon.odds >= filters.minOdds!);
    }
    if (filters.maxOdds !== undefined) {
      coupons = coupons.filter(coupon => coupon.odds <= filters.maxOdds!);
    }

    return coupons;
  } catch (error) {
    console.error('Error searching coupons:', error);
    throw error;
  }
};