import React from 'react';
import { SPORTS_CATEGORIES } from '../lib/constants';
import clsx from 'clsx';

interface SportFilterProps {
  selectedSport: string | null;
  onSelectSport: (sport: string | null) => void;
}

const SportFilter: React.FC<SportFilterProps> = ({ selectedSport, onSelectSport }) => {
  return (
    <div className="flex flex-wrap gap-2 mb-6">
      <button
        onClick={() => onSelectSport(null)}
        className={clsx(
          'px-4 py-2 rounded-full text-sm font-medium transition',
          selectedSport === null
            ? 'bg-blue-600 text-white'
            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
        )}
      >
        Tous
      </button>
      {SPORTS_CATEGORIES.map((sport) => (
        <button
          key={sport.id}
          onClick={() => onSelectSport(sport.id)}
          className={clsx(
            'px-4 py-2 rounded-full text-sm font-medium transition flex items-center space-x-2',
            selectedSport === sport.id
              ? 'bg-blue-600 text-white'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          )}
        >
          <span>{sport.icon}</span>
          <span>{sport.name}</span>
        </button>
      ))}
    </div>
  );
};

export default SportFilter;