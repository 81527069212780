import { 
  collection, 
  doc, 
  getDoc, 
  getDocs,
  updateDoc,
  query,
  where,
  orderBy,
  serverTimestamp,
  writeBatch
} from 'firebase/firestore';
import { db } from '../config';

export const updateCouponResult = async (
  couponId: string, 
  result: 'won' | 'lost',
  adminId: string
) => {
  try {
    const batch = writeBatch(db);

    // Get coupon document
    const couponRef = doc(db, 'coupons', couponId);
    const couponSnap = await getDoc(couponRef);
    
    if (!couponSnap.exists()) {
      throw new Error('Coupon not found');
    }

    const couponData = couponSnap.data();
    const pronosticId = couponData.pronosticId;

    // Update coupon status and result
    batch.update(couponRef, {
      status: 'completed',
      result,
      updatedAt: serverTimestamp(),
      updatedBy: adminId
    });

    // Get pronostic document
    const pronosticRef = doc(db, 'pronostics', pronosticId);
    const pronosticSnap = await getDoc(pronosticRef);
    
    if (pronosticSnap.exists()) {
      // Get all completed coupons for this pronostic
      const couponsQuery = query(
        collection(db, 'coupons'),
        where('pronosticId', '==', pronosticId),
        where('status', '==', 'completed')
      );
      const couponsSnap = await getDocs(couponsQuery);
      
      // Calculate total and won coupons
      const totalCoupons = couponsSnap.size + 1; // Include current coupon
      const wonCoupons = couponsSnap.docs.reduce((count, doc) => {
        return doc.data().result === 'won' ? count + 1 : count;
      }, result === 'won' ? 1 : 0); // Include current coupon if won

      // Calculate success rate
      const successRate = Math.round((wonCoupons / totalCoupons) * 100);

      // Update pronostic stats
      batch.update(pronosticRef, {
        totalCoupons,
        wonCoupons,
        successRate,
        updatedAt: serverTimestamp()
      });

      // Update all active coupons with new stats
      const activeCouponsQuery = query(
        collection(db, 'coupons'),
        where('pronosticId', '==', pronosticId),
        where('status', '==', 'active')
      );
      const activeCouponsSnap = await getDocs(activeCouponsQuery);

      activeCouponsSnap.docs.forEach(doc => {
        batch.update(doc.ref, {
          successRate,
          wonCoupons,
          totalCoupons
        });
      });
    }

    // Commit all updates
    await batch.commit();
    return true;
  } catch (error) {
    console.error('Error updating coupon result:', error);
    throw error;
  }
};